// Images
import coloredWaves from '../assets/images/colored waves.svg';
import logo from '../assets/images/Fit4Grit Logo.png';

// Components
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <footer>
            <img src={coloredWaves} className="footer-waves" />
            <div className="footer">
                <div className="container py-4 py-lg-5">
                    <div className="row justify-content-center">
                        <div className="col col-6 text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start footer-text"><img src={logo} className="footer-logo" />
                            <p className="text-muted footer-text">Empowering youth, building confidence, teaching defense-self, and encouraging physical fitness, through no cost martial arts classes delivered virtually throughout the U.S. and across the globe.</p>
                            <p className="text-muted footer-text">Fit4Grit Academy is a registered 501(c)3 nonprofit organization founded in 2020 and based in the U.S.</p>
                        </div>
                        <div className="col text-center text-lg-start d-flex flex-column item">
                            <h3 className="footer-title">Information</h3>
                            <ul className="list-unstyled">
                                <li className="footer-item"><a className="link-secondary footer-text" href="https://linktr.ee/fit4grit.academy/" target="_blank" rel="noreferrer">Linktree</a></li>
                                <li className="footer-item"><Link className="link-secondary footer-text" to="/terms-and-conditions">Terms and Conditions</Link></li>
                                <li className="footer-item"><Link className="link-secondary footer-text" to="/privacy-policy">Privacy Policy</Link></li>
                                <li className="footer-item"><Link className="link-secondary footer-text" to="/sign-up/students">Student Agreement &amp; Liability</Link></li>
                                <li className="footer-item"><Link className="link-secondary footer-text" to="/sign-up/instructors">Instructor Agreement &amp; Liability</Link></li>
                                <li className="footer-item"><HashLink className="link-secondary footer-text" to="/classes#real-time">Real Time Virtual Classes</HashLink></li>
                                <li className="footer-item"><HashLink className="link-secondary footer-text" to="/classes#self-paced">Self-Paced Video Lessons</HashLink></li>
                            </ul>
                        </div>
                        <div className="col col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column">
                            <h3 className="footer-title">Contact Us</h3>
                            <ul className="list-unstyled">
                                <li className="footer-item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" className="footer-contact-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </svg><a className="footer-contact footer-text" href="mailto:info@fit4grit.org">info@fit4grit.org</a></li>
                                <li className="footer-item"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" className="footer-contact-icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </svg><a className="footer-contact footer-text" href="tel:5108584748">+1 (510) 858 4748</a></li>
                            </ul>
                            <ul className="list-inline d-flex justify-content-center justify-content-md-start">
                                <li className="list-inline-item"><a className="footer-social d-flex justify-content-center align-items-center facebook-colors" href="https://www.facebook.com/fit4grit.academy/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon className="footer-social-icon" icon={faFacebookF} />

                                </a></li>
                                <li className="list-inline-item"><a className="footer-social d-flex justify-content-center align-items-center instagram-colors" href="https://www.instagram.com/fit4grit_academy/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon className="footer-social-icon" icon={faInstagram} />
                                </a></li>
                                <li className="list-inline-item"><a className="footer-social d-flex justify-content-center align-items-center linkedin-colors" href="https://www.linkedin.com/in/fit4grit-academy-7a25a01b2/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon className="footer-social-icon" icon={faLinkedinIn} />
                                </a></li>
                                <li className="list-inline-item"><a className="footer-social d-flex justify-content-center align-items-center youtube-colors" href="https://www.youtube.com/channel/UC_FW3kmNRjj_q1fbrplVR7w/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon className="footer-social-icon" icon={faYoutube} /></a></li>
                            </ul>
                        </div>
                    </div>
                    <hr className="footer-hr" />
                    <p className="text-center text-muted mb-0 footer-text">Copyright © 2022 Fit4Grit Academy - All Rights Reserved</p>
                </div>
            </div>
        </footer>
    )
}
