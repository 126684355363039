// React
import { useState, useEffect, useRef, useContext } from "react";

// Axios
import axios from "axios";

// React Router DOM
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";

// Formik
import { Formik, Form } from 'formik';

// Yup
import * as Yup from 'yup';

// React Bootstrap
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';

// classnames
import classNames from 'classnames';

// CSS
import styles from './home.module.css';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faSpinner, faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';

// Images
import taekwondo from '../../assets/images/Taekwondo.svg';
import selfControl from '../../assets/images/Self-Control.svg';
import rankBelts from '../../assets/images/Rank Belts.svg';
import instructor from '../../assets/images/Instructor.svg';
import whiteBelt from '../../assets/images/White Belt.svg';
import trainingVideos from '../../assets/images/Online Training Videos.svg';
import funding from '../../assets/images/Beginner.svg';
import studentAndInstructor from '../../assets/images/Student and Instructor.svg';

// Video
import video1 from '../../assets/videos/gallery/video 1.mp4';
import video2 from '../../assets/videos/gallery/video 2.mp4';
import video3 from '../../assets/videos/gallery/video 3.mp4';
import video4 from '../../assets/videos/gallery/video 4.mp4';
import video5 from '../../assets/videos/gallery/video 5.mp4';
import video6 from '../../assets/videos/gallery/video 6.mp4';
import video7 from '../../assets/videos/gallery/video 7.mp4';
import video8 from '../../assets/videos/gallery/video 8.mp4';

// Components
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import Input, { MultiLineInput } from '../../components/Input';

import useIsInViewport from '../../components/UseIsInViewport';

// Context
import { ClassesContext } from '../../index';

export default function Home() {

    const classes = useContext(ClassesContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (classes) {
            setLoading(false);
        }
    }, [classes]);

    return (
        <>
            <NavBar dark={true} />
            <section className={styles.section1}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex justify-content-center align-items-center"><img className={styles.section1_img} src={taekwondo} alt="Taekwondo" /></div>
                        <div className="col d-flex flex-column justify-content-lg-center order-md-first">
                            <h1 className="text-center text-md-start">Fit4Grit Academy</h1>
                            <p className="text-center text-md-start">Mentoring and Empowering Youth world-wide with the gift of Confidence, Self-Defense and Fitness through Martial Arts.</p>
                            <div className="row justify-content-center justify-content-md-start">
                                <div className={classNames("col col-auto", styles.section1_btn_col)}><HashLink className={classNames("btn btn-secondary", styles.section1_btn)} role="button" to="/#contact-us">Contact Us</HashLink></div>
                                <div className={classNames("col col-auto", styles.section1_btn_col)}><Link className={classNames("btn btn-primary", styles.section1_btn)} role="button" to="/sign-up">Sign Up</Link></div>
                                <div className={classNames("col col-auto", styles.section1_btn_col)}><a className={classNames("btn btn-primary", styles.section1_btn)} role="button" href=" https://gofund.me/fa209ebc" target="_blank" rel="noreferrer">Donate</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-us" className={styles.section2}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="text-center">Who We Are</h3>
                            <h2 className="text-center">About Us</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)}>
                            <img src={selfControl} className={styles.section2_col_icon} />
                            <h4 className={classNames("text-center", styles.section2_col_title)}>Our Mission</h4>
                            <p className={classNames("text-center", styles.section2_col_p)}>At Fit4Grit Academy we work to leverage the benefits of an electronically connected world, to bring the multitude of advantages that martial arts training offers youth. Our mission is to mentor and empower youth, build confidence, teach self-defense, and encourage physical fitness through no cost martial arts classes delivered virtually throughout the U.S. and across the globe. Arming youth with skills critical for personal and professional growth is a goal we aim to achieve - one child at a time.</p>
                            <HashLink className={classNames("btn btn-secondary", styles.section2_btn)} role="button" to="/about-us#mission">Read More</HashLink>
                        </div>
                        <div className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)}>
                            <img src={rankBelts} className={styles.section2_col_icon} />
                            <h4 className={classNames("text-center", styles.section2_col_title)}>Our History</h4>
                            <p className={classNames("text-center", styles.section2_col_p)}>Fit4Grit, was launched in 2020. Transitioning from a handful of casual online classes offered to friends and family, the program quickly grew into a structured academy with a purposeful vision to mentor youth. Initially serving youth in the U.S., the Academy’s free online martial arts classes have expanded to serve students globally with a focus on reaching at-risk youth in communities where opportunities are scarce. Fit4Grit has now grown to serve over 500 students with 20 carefully designed courses.</p>
                            <HashLink className={classNames("btn btn-secondary", styles.section2_btn)} role="button" to="/about-us#history">Read More</HashLink>
                        </div>
                        <div className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)}>
                            <img src={instructor} className={styles.section2_col_icon} />
                            <h4 className={classNames("text-center", styles.section2_col_title)}>Our Team</h4>
                            <p className={classNames("text-center", styles.section2_col_p)}>At its inception, Fit4Grit classes were taught by one instructor, the founder, Adarsh Gupta who had  a clear goal in mind: applying martial arts to mentor youth.  With a background in Taekwondo for over ten years and a wealth of experience teaching martial arts to youth, Adarsh spearheaded a growing team.  As the program grew, it attracted other youth to volunteer with Fit4Grit. Today we are privileged to have over ten highly qualified instructors that are passionate to advance the mission of Fit4Grit. </p>
                            <HashLink className={classNames("btn btn-secondary", styles.section2_btn)} role="button" to="/about-us#team">Read More</HashLink>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={classNames("col-md-4 d-flex justify-content-center align-items-center", styles.section3_col)}>
                            <picture><img className={styles.section3_col_icon} src={whiteBelt} /></picture>
                            <div className={classNames("text-center", styles.section3_col_counter)}>
                                <h2>500+</h2>
                                <p>Students</p>
                            </div>
                        </div>
                        <div className={classNames("col-md-4 d-flex justify-content-center align-items-center", styles.section3_col)}>
                            <picture><img className={styles.section3_col_icon} src={trainingVideos} /></picture>
                            <div className={classNames("text-center", styles.section3_col_counter)}>
                                <h2>20+</h2>
                                <p>Courses</p>
                            </div>
                        </div>
                        <div className={classNames("col-md-4 d-flex justify-content-center align-items-center", styles.section3_col)}>
                            <picture><img className={styles.section3_col_icon} src={instructor} /></picture>
                            <div className={classNames("text-center", styles.section3_col_counter)}>
                                <h2>10+</h2>
                                <p>Instructors</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="testimonials" className={styles.section4}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="text-center"><strong>OUR PARENTS AND STUDENTS</strong></h3>
                            <h2 className="text-center"><strong>Success Testimonials</strong></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </section>
            <section id="popular">
                <Container>
                    <h3 className="text-center">Sign Up Now</h3>
                    <h2 className="text-center">Popular Classes</h2>
                    <p className="text-center">Check out our latest and most popular classes</p>
                    <Row className="d-flex justify-content-center">
                        {loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => !classes[id].archived).length > 0 ? Object.keys(classes).filter((id) => !classes[id].archived).splice(0, Math.min(3, Object.keys(classes).filter((id) => !classes[id].archived).length)).map((id) => {
                            const data = classes[id];
                            return (
                                <div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
                                    <img className={styles.section2_col_img} src={data.image ? data.image : taekwondo} alt="Course Image" />
                                    <Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
                                        <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                            <FontAwesomeIcon icon={faCalendarDays} className={styles.col_icon} />
                                            <p className={styles.section2_col_data}>
                                                {data.days.length > 1 ? data.days.map((day, index) => {
                                                    if (index === data.days.length - 1) {
                                                        return day.substring(0, 3);
                                                    } else {
                                                        return day.substring(0, 3) + ", ";
                                                    }
                                                }) : data.days[0]}
                                            </p>
                                        </Col>
                                        <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                            <FontAwesomeIcon icon={faClock} className={styles.col_icon} />
                                            <p className={styles.section2_col_data}>
                                                {data.start + " - " + data.end}
                                            </p>
                                        </Col>
                                    </Row>
                                    <h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
                                    <p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
                                    <Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
                                </div>
                            )

                        }) : <p>There are no active classes at the momment</p>}
                    </Row>
                </Container>
            </section>
            <section id="help">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={classNames("col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start", styles.section5_col)}>
                            <img src={funding} className={styles.section5_col_img} />
                            <h2 className={styles.section5_col_title}>Fund Fit4Grit Academy</h2>
                            <p>Students worldwide grow up in situations where martial arts and self-defense classes are not an option. Other students face situations where they do not have the confidence or self-esteem to grow into successful people or where they face health problems due to the lack of fitness education. But Fit4Grit Academy can help address these problems with your support. Clicking below will guide you to donate to help support our cause!</p>
                            <a className="btn btn-secondary" role="button" href=" https://gofund.me/fa209ebc" target="_blank" rel="noreferrer">Donate Here</a>
                        </div>
                        <div className={classNames("col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start", styles.section5_col)}>
                            <img src={studentAndInstructor} className={styles.section5_col_img} />
                            <h2 className={styles.section5_col_title}>Become an Instructor</h2>
                            <p>Martial arts is the art of agility, strength, and discipline. People worldwide strive to attain your skills but are never given the opportunity… till now! With your help as a verified instructor, you can teach students worldwide with your own class, in a mix of self-defense, fitness, and confidence, all through Fit4Grit Academy. And the process to become verified is simple. Simply click below to get started on your instructor process, and see where you can make a difference.</p>
                            <Link className="btn btn-secondary" role="button" to="/sign-up/instructors">Sign Up To Be An Instructor</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="text-center"><strong>Get In Touch</strong></h3>
                            <h2 className="text-center"><strong>Contact Us</strong></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <ContactUs />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

function Testimonials() {

    const [activeIndex, setActiveIndex] = useState(0);
    const carousel = useRef();
    const carouselContainer = useRef();
    const isVisible = useIsInViewport(carouselContainer);
    const [interval, setInterval] = useState(null);

    useEffect(() => {
        if (isVisible) {
            setInterval(4000);
        } else {
            setInterval(null);
        }
    }, [isVisible]);

    const handleSelect = (selectedIndex, e) => {
        carousel.current.element.children[1].children[activeIndex].pause();
        setActiveIndex(selectedIndex);
        carousel.current.element.children[1].children[activeIndex].play();
    };

    return (
        <div ref={carouselContainer}>
            <Carousel ref={carousel} interval={interval} activeIndex={activeIndex} onSelect={handleSelect} className={classNames("d-flex justify-content-center align-items-center", styles.section4_slider)} prevIcon={<FontAwesomeIcon icon={faAngleLeft} className={styles.section4_slider_prev} />} nextIcon={<FontAwesomeIcon icon={faAngleRight} className={styles.section4_slider_next} />}>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Adarsh</strong> has been a <strong>great online instructor</strong> for my children. He makes the classes <strong>engaging</strong> and <strong>fun</strong>. This is a great way to get some physical activity and an introduction to Taekwondo from the comfort of your home.”<em> - A Fit4Grit Parent</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video1} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Tanish</strong>'s class has been an <strong>incredible experience</strong>! He is great at building <strong>confidence</strong> in the kids and making them <strong>feel welcomed</strong>. He is always answering questions <strong>patiently</strong> as he guides the kids through techniques. My son has found a new interest in Taekwondo forms because of the <strong>fun</strong> activities and <strong>life lessons</strong> he's learned from this Fit4Grit class!”<em> - A Fit4Grit Parent</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video2} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“I have enrolled my daughter in <strong>Shalini</strong>'s class without any prior experience in martial arts. I was very <strong>impressed</strong> how well these kids are teaching them despite only being high schoolers. She continues to <strong>instill good values</strong> during their lessons. I am <strong>very happy</strong> with the classes that we are taking and I am <strong>looking forward</strong> to seeing what my daughter will learn from her class.”<em> - A Fit4Grit Parent</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video3} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Tanish</strong>'s class has been very <strong>fulfilling</strong> for my child. In this time of COVID-19, Tanish's class has been a <strong>valuable</strong> way for my child to get necessary fitness and <strong>confidence</strong> from home. Lessons about the importance of confidence and <strong>respect</strong> for others are often hard to teach kids, but Tanish has <strong>taught</strong> these <strong>well</strong> through the Taekwondo techniques he teaches in class. Tanish's class is very <strong>organized</strong> and my child is always eagerly <strong>anticipating</strong> his class. Highly recommend it!”<em> - A Fit4Grit Parent</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video4} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Excellent effort</strong> by Fit4Grit Academy to keep kids <strong>engaged</strong> and <strong>motivated</strong> during online sessions! Their instructors are very <strong>patient</strong> and <strong>skillful</strong>, and they understand each student's abilities and help bring out the best in them.”<em> - A Fit4Grit Parent</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video5} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“This <strong>amazing</strong> class has <strong>built confidence</strong> in me. I am not scared to try challenging activities.”<em> - A Fit4Grit Student</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video6} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Adarsh</strong> is an <strong>amazing</strong> instructor. His technique, <strong>patience</strong> with the kids, and ability to inspire, <strong>respect</strong>, and <strong>discipline</strong> are truly amazing”<em> - A Fit4Grit Student</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video7} />
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <p className="text-center">“<strong>Shalini</strong> has a very <strong>structured</strong> and planned approach for every session. She <strong>follows up</strong> the sessions with homework, <strong>encouraging</strong> kids to practice the moves and be ready to <strong>learn</strong> new things in next session. She explains the concepts of Wushu in easy language, <strong>demonstrates</strong> the moves well and provides <strong>supportive feedback</strong> to the kids. Wushu focuses on details in body movements which helps kids to <strong>build focus</strong> and strength. Her session, specifically in the times of COVID, have provided a much needed <strong>fun</strong> and <strong>energizing</strong> activity for my child.”<em> - A Fit4Grit Student</em></p>
                </Carousel.Item>
                <Carousel.Item className={styles.section4_slide}>
                    <video height="400px" src={video8} />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function ContactUs() {

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const formikConfig = {
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .required('Required'),
            email: Yup
                .string()
                .email('Please enter a valid email')
                .required('Please enter an email'),
            phone: Yup
                .string(),
            subject: Yup
                .string()
                .required('Please enter a subject'),
            message: Yup
                .string()
                .required('Please enter a message'),
        }),
        onSubmit: async (values, formikBag) => {
            if (!hasSubmitted) {
                const msg = {
                    to: 'admin@fit4grit.org',
                    from: 'noreply@fit4grit.org',
                    subject: 'New Contact Form Submission - ' + values.subject,
                    text: 'Name: ' + values.name + '\nEmail: ' + values.email + '\nPhone: ' + values.phone + '\nSubject: ' + values.subject + '\nMessage: ' + values.message,
                    html: '<p>Name: ' + values.name + '<br/>Email: ' + values.email + '<br/>Phone: ' + values.phone + '<br/>Subject: ' + values.subject + '<br/>Message: ' + values.message + '</p>',
                }
                await axios.post('/api/contact', msg);
                setHasSubmitted(true);
            }
            formikBag.setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    }

    return (
        <Formik {...formikConfig}>
            {({ touched, isValid, isSubmitting }) => (
                <Form className="contact-us">
                    <div className="d-flex justify-content-center align-items-start">
                        <Input fieldName="name" type="text" name="Name" placeholder="Name" autoComplete="on" />
                        <Input fieldName="email" type="email" name="Email" placeholder="Email" inputMode="email" autoComplete="on" />
                    </div>
                    <div className="d-flex justify-content-center align-items-start">
                        <Input fieldName="phone" type="tel" name="Phone" placeholder="Phone" inputMode="tel" autoComplete="on" />
                        <Input fieldName="subject" type="text" name="Subject" placeholder="Subject" />
                    </div>
                    <div className="d-flex justify-content-center align-items-start">
                        <MultiLineInput fieldName="message" type="text" name="Message" placeholder="Message" />
                    </div>
                    <div className="d-flex justify-content-center align-items-start">
                        <Button variant="secondary" type="submit" disabled={hasSubmitted || !(touched.name && touched.email && touched.subject && touched.message && isValid)}>
                            {!hasSubmitted ? isSubmitting ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} /> : "Contact Us" : "Submitted"}
                        </Button>
                    </div>
                </Form>
            )
            }
        </Formik >
    )
}
