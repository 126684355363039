// Formik
import { Field } from 'formik';

// classnames
import classNames from 'classnames';

export default function Input({ fieldName, type = "text", placeholder, autoComplete = "off", ...props }) {
    return (
        <div className="form-group">
            <Field name={fieldName} >
                {({ field, form, meta }) => (
                    <>
                        <div className={classNames("form-control", { "error": meta.error })}>
                            <input className="form-control-inner" name={fieldName} type={type} placeholder={placeholder} autoComplete={autoComplete} {...props} {...field}
                                onBlur={(e) => {
                                    form.handleBlur(e);
                                    form.validateField(fieldName);
                                }} />
                        </div>
                        <p className="form-control-message error">{meta.error}</p>
                    </>
                )}
            </Field>
        </div>
    );
}

export function MultiLineInput({ fieldName, placeholder, ...props }) {
    return (
        <div className="form-group">
            <Field name={fieldName} >
                {({ field, form, meta }) => (
                    <>
                        <div className={classNames("form-control", { "error": meta.error })}>
                            <textarea className="form-control-inner" name={fieldName} placeholder={placeholder} {...props} {...field}
                                onBlur={(e) => {
                                    form.handleBlur(e);
                                    form.validateField(fieldName);
                                }} />
                        </div>
                        <p className="form-control-message error">{meta.error}</p>
                    </>
                )}
            </Field>
        </div>
    );
}
