// React
import { useEffect, useContext } from 'react';

// React Router DOM
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';

// Context
import { AuthContext } from '../../index';

export default function ProtectedRoutes({ path, children, ...props }) {
    const location = useLocation();
    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);

    useEffect(() => {
        if (currentUser !== undefined && !currentUser) {
            navigate('/login', { replace: true, state: { redirectURL: location.pathname} });
        }
    }, [currentUser])

    return currentUser ? <Outlet /> : <Navigate to="/login" replace={true} state={{ redirectURL: location.pathname}}/>;
}
