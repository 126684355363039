// React Boostrap
import { Container } from 'react-bootstrap';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function PrivacyPolicy() {
    return (
        <>
            <Header title="Privacy Policy" />
            <section>
                <Container>
                    <b>By using the Services, you agree to the terms of this Privacy Policy.</b> You shouldn't use the Services if you don't agree with this Privacy Policy or any other agreement that governs your use of the Services.
                    <h2>Table of Contents</h2>
                    <ol>
                        <li> What Data We Get</li>
                        <li> How We Get Data About You</li>
                        <li> What We Use Your Data For</li>
                        <li> Who We Share Your Data With</li>
                        <li> Security</li>
                        <li> Your Rights</li>
                        <li> Jurisdiction-Specific Rules</li>
                        <li> Updates &amp; Contact Info</li>
                    </ol>
                    Cookie Policy
                    <h2>1. What Data We Get</h2>
                    We collect certain data from you directly, like information you enter yourself, data about your participation in courses, and data from third-party platforms you connect with Fit4Grit Academy.
                    <h3>1.1 Data You Provide to Us</h3>
                    We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.

                    When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Account Data</b></td>
                                <td>In order to use certain features (like enrolling in a course), you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, password, gender, and date of birth, and assign you a unique identifying number (<b>“Account Data”</b>).</td>
                            </tr>
                            <tr>
                                <td><b>Profile Data</b></td>
                                <td>You can also choose to provide profile information like a photo, headline, website link, or other data. Your Profile Data will be publicly viewable by others.</td>
                            </tr>
                            <tr>
                                <td><b>Shared Content</b></td>
                                <td>Parts of the Services let you interact with other users or share content publicly, including by, asking or answering questions, sending messages to students or instructors, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted.</td>
                            </tr>
                            <tr>
                                <td><b>Course Data</b></td>
                                <td>When you enroll in and take courses, we collect certain data including which courses you've started and completed; your exchanges with instructors, teaching assistants, and other students; and essays, answers to questions, and other items submitted to satisfy course optional/required requirements.</td>
                            </tr>
                            <tr>
                                <td><b>Data About Your Accounts on Other Services</b></td>
                                <td>We may obtain certain information through your social media or other online accounts if they are connected to your Fit4Grit Academy account. If you login to Fit4Grit Academy via Facebook or another third-party platform or service, we may ask for your permission to access certain information about that other account. For example, depending on the platform or service we may collect your name, profile picture, account ID number, login email address, location, physical location of your access devices, gender, birthday, and list of friends or contacts.

                                    Those platforms and services make information available to us through their APIs. The information we receive depends on what information you (via your privacy settings) or the platform or service decide to give us.

                                    If you access or use our Services through a third-party platform or service, or click on any third-party links, the collection, use, and sharing of your data will also be subject to the privacy policies and other agreements of that third party.</td>
                            </tr>
                            <tr>
                                <td><b>Sweepstakes, Promotions, and Surveys</b></td>
                                <td>We may invite you to complete a survey or participate in a promotion (like a contest, sweepstakes, or challenge), either through the Services or a third-party platform. If you participate, we will collect and store the data you provide as part of participating, such as your name, email address, date of birth, or phone number. That data is subject to this Privacy Policy unless otherwise stated in the official rules of the promotion or in another privacy policy. The data collected will be used to administer the promotion or survey, including for notifying winners and distributing rewards. To receive a reward, you may be required to allow us to post some of your information publicly (like on a winner's page). Where we use a third-party platform to administer a survey or promotion, the third party's privacy policy will apply.</td>
                            </tr>
                            <tr>
                                <td><b>Communications and Support</b></td>
                                <td>If you contact us for support or to report a problem or concern (regardless of whether you have created an account), we collect and store your contact information, messages, and other data about you like your name, email address, location, operating system, IP address, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern, in accordance with this Privacy Policy.</td>
                            </tr>
                        </tbody>
                    </table>
                    The data listed above is stored by us and associated with your account.
                    <h3>1.2 Data We Collect through Automated Means</h3>
                    When you access the Services (including browsing courses), we collect certain data by automated means, including:
                    <table>
                        <tbody>
                            <tr>
                                <td><b>System Data</b></td>
                                <td>Technical data about your computer or device, like your IP address, device type, operating system type and version, unique device identifiers, browser, browser language, domain and other systems data, and platform types (<b>“System Data”</b>).</td>
                            </tr>
                            <tr>
                                <td><b>Usage Data</b></td>
                                <td>Usage statistics about your interactions with the Services, including courses accessed, time spent on pages or the Service, pages visited, features used, your search queries, click data, date and time, and other data regarding your use of the Services (<b>“Usage Data”</b>).</td>
                            </tr>
                            <tr>
                                <td><b>Approximate Geographic Data</b></td>
                                <td>An approximate geographic location, including information like country, city, and geographic coordinates, calculated based on your IP address.</td>
                            </tr>
                        </tbody>
                    </table>
                    The data listed above is collected through the use of server log files and tracking technologies, as detailed in the “Cookies and Data Collection Tools” section below. It is stored by us and associated with your account.
                    <h2>2. How We Get Data About You</h2>
                    We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.
                    <h3>2.1 Cookies and Data Collection Tools</h3>
                    As detailed in our Cookie Policy, Fit4Grit Academy and service providers acting on our behalf (like Google Analytics and third party advertisers) use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (together, <b>“Data Collection Tools”</b>) when you access and use the Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data (as detailed in Section 1) when you use the Services. In some cases, we tie data gathered through those Data Collection Tools to other data that we collect as described in this Privacy Policy.

                    We may use cookies (small files that websites send to your device to uniquely identify your browser or device or to store data in your browser) for things like analyzing your use of the Services, personalizing your experience, making it easier to log into the Services, and recognizing you when you return. We may use web beacons (small objects that allow us to measure the actions of visitors and users using the Services) for things like identifying whether a page was visited, identifying whether an email was opened, and advertising more efficiently by excluding current users from certain promotional messages or identifying the source of a new mobile app download.

                    Fit4Grit Academy may use the following types of cookies:
                    <ul>
                        <li aria-level="1">Preferences: cookies that remember data about your browser and preferred settings that affect the appearance and behavior of the Services (like your preferred language).</li>
                        <li aria-level="1">Security: cookies used to enable you to log in and access the Services; protect against fraudulent logins; and help detect and prevent abuse or unauthorized use of your account.</li>
                        <li aria-level="1">Functional: cookies that store functional settings (like the volume level you set for video playback).</li>
                        <li aria-level="1">Session State: cookies that track your interactions with the Services to help us improve the Services and your browsing experience, remember your login details, and enable processing of your course purchases. These are strictly necessary for the Services to work properly, so if you disable them then certain functionalities will break or be unavailable.</li>
                    </ul>
                    You can set your web browser to alert you about attempts to place cookies on your computer, limit the types of cookies you allow, or refuse cookies altogether. If you do, you may not be able to use some or all features of the Services, and your experience may be different or less functional.

                    Some of the third-party partners who provide certain features on our site may also use Local Storage Objects (also known as flash cookies or LSOs) to collect and store data.
                    <h3>2.2 Analytics</h3>
                    We use third-party browser and mobile analytics services like Google Analytics, Hotjar, and Intercom on the Services. These services use Data Collection Tools to help us analyze your use of the Services, including information like the third-party website you arrive from, how often you visit, events within the Services, usage and performance data, and where the application was downloaded from. We use this data to improve the Services, better understand how the Services perform on different devices, and provide information that may be of interest to you.
                    <h3>2.3 Online Advertising</h3>
                    We use third-party advertising services like Taboola, Facebook, Google's ad services, and other ad networks and ad servers to deliver advertising about our Services on other websites and applications you use. The ads may be based on things we know about you, like your Usage Data and System Data (as detailed in Section 1), and things that these ad service providers know about you based on their tracking data. The ads can be based on your recent activity or activity over time and across other sites and services, and may be tailored to your interests.

                    Depending on the types of advertising services we use, they may place cookies or other tracking technologies on your computer, phone, or other device to collect data about your use of our Services, and may access those tracking technologies in order to serve these tailored advertisements to you. To help deliver tailored advertising, we may provide these service providers with a hashed, anonymized version of your email address (in a non-human-readable form) and content that you share publicly on the Services.

                    When using mobile applications you may also receive tailored in-app advertisements. Apple iOS, Android OS, and Microsoft Windows each provide their own instructions on how to control in-app tailored advertising. For other devices and operating systems, you should review your privacy settings or contact your platform operator.
                    <h2>3. What We Use Your Data For</h2>
                    We use your data to do things like provide our Services, communicate with you, troubleshoot issues, secure against fraud and abuse, improve and update our Services, analyze how people use our Services, serve personalized advertising, and as required by law or necessary for safety and integrity.

                    We use the data we collect through your use of the Services to:
                    <ul>
                        <li aria-level="1">Provide and administer the Services, including to display customized content and facilitate communication with other users;</li>
                        <li aria-level="1">Process your requests and orders for courses, products, specific services, information, or features;</li>
                        <li aria-level="1">Communicate with you about your account by:
                            <ul>
                                <li aria-level="2">Responding to your questions and concerns;</li>
                                <li aria-level="2">Sending you administrative messages and information, including messages from instructors and teaching assistants, notifications about changes to our Service, and updates to our agreements;</li>
                                <li aria-level="2">Sending you information and in-app messages about your progress in courses, rewards programs, new services, new features, promotions, newsletters, and other available courses (which you can opt out of at any time);</li>
                                <li aria-level="2">Sending push notifications to your wireless device to provide updates and other relevant messages (which you can manage from the “options” or “settings” page of the mobile app);</li>
                            </ul>
                        </li>
                        <li aria-level="1">Manage your account preferences;</li>
                        <li aria-level="1">Facilitate the Services' technical functioning, including troubleshooting and resolving issues, securing the Services, and preventing fraud and abuse;</li>
                        <li aria-level="1">Solicit feedback from users;</li>
                        <li aria-level="1">Market and administer surveys and promotions administered or sponsored by Fit4Grit Academy;</li>
                        <li aria-level="1">Learn more about you by linking your data with additional data through third-party data providers or analyzing the data with the help of analytics service providers;</li>
                        <li aria-level="1">Identify unique users across devices;</li>
                        <li aria-level="1">Tailor advertisements across devices;</li>
                        <li aria-level="1">Improve our Services and develop new products, services, and features;</li>
                        <li aria-level="1">Analyze trends and traffic, track purchases, and track usage data;</li>
                        <li aria-level="1">Advertise the Services on third-party websites and applications;</li>
                        <li aria-level="1">As required or permitted by law; or</li>
                        <li aria-level="1">As we, in our sole discretion, otherwise determine to be necessary to ensure the safety or integrity of our users, employees, third parties, the public, or our Services.</li>
                    </ul>
                    <h2>4. Who We Share Your Data With</h2>
                    We share certain data about you with instructors, other students, companies performing services for us, our business partners, analytics and data enrichment providers, your social media providers, companies helping us run promotions and surveys, and advertising companies who help us promote our Services. We may also share your data as needed for security, legal compliance, or as part of a corporate restructuring. Lastly, we can share data in other ways if it is aggregated or de-identified or if we get your consent.

                    We may share your data with third parties under the following circumstances or as otherwise described in this Privacy Policy:
                    <ul>
                        <li aria-level="1">With Your Instructors: We share data that we have about you (except your email address) with instructors or teaching assistants for courses you enroll in or request information about, so they can improve their courses for you and other students. This data may include things like your browser language, operating system, device settings, the site that brought you to Fit4Grit Academy, and your activities on Fit4Grit Academy. If we collect additional data about you (like age or gender), we may share that too. We will not share your email address with instructors or teaching assistants. We also enable our instructors to implement Google Analytics on their course pages to track sources of traffic to their courses and optimize their course pages.</li>
                        <li aria-level="1">With Other Students and Instructors: Depending on your settings, your shared content and profile data may be publicly viewable, including to other students and instructors. If you ask a question to an instructor or teaching assistant, your information (including your name) may also be publicly viewable by other users depending on your settings.</li>
                        <li aria-level="1">With Service Providers, Contractors, and Agents: We share your data with third-party companies who perform services on our behalf, like payment processing, data analysis, marketing and advertising services (including retargeted advertising), email and hosting services, and customer services and support. These service providers may access your personal data and are required to use it solely as we direct, to provide our requested service.</li>
                        <li aria-level="1">With Business Partners: We may have agreements with other websites and platforms to distribute our Services and drive traffic to Fit4Grit Academy. Depending on your location, we may share your data with these partners.</li>
                        <li aria-level="1">With Analytics and Data Enrichment Services: As part of our use of third-party analytics tools like Google Analytics and data enrichment services like Clearbit, we share certain contact information, Account Data, System Data, Usage Data (as detailed in Section 1), or de-identified data as needed. De-identified data means data where we've removed things like your name and email address and replaced it with a token ID. This allows these providers to provide analytics services or match your data with publicly-available database information (including contact and social information from other sources). We do this to communicate with you in a more effective and customized manner.</li>
                        <li aria-level="1">To Power Social Media Features: The social media features in the Services (like the Facebook Like button) may allow the third-party social media provider to collect things like your IP address and which page of the Services you're visiting, and to set a cookie to enable the feature. Your interactions with these features are governed by the third-party company's privacy policy.</li>
                        <li aria-level="1">To Administer Promotions and Surveys: we may share your data as necessary to administer, market, or sponsor promotions and surveys you choose to participate in, as required by applicable law (like to provide a winners list or make required filings), or in accordance with the rules of the promotion or survey.</li>
                        <li aria-level="1">For Advertising: If we decide to offer advertising in the future, we may use and share certain System Data and Usage Data with third-party advertisers and networks to show general demographic and preference information among our users. We may also allow advertisers to collect System Data through Data Collection Tools (as detailed in Section 2.1), and to use this data to offer you targeted ad delivery to personalize your user experience (through behavioral advertising) and undertake web analytics. Advertisers may also share with us the data they collect about you. To learn more or opt out from participating ad networks' behavioral advertising, see Section 6.1 (Your Choices About the Use of Your Data) below. Note that if you opt out, you'll continue to be served generic ads.</li>
                        <li aria-level="1">For Security and Legal Compliance: We may disclose your data to third parties if we (in our sole discretion) have a good faith belief that the disclosure is:
                            <ul>
                                <li aria-level="2">Permitted or required by law;</li>
                                <li aria-level="2">Requested as part of a judicial, governmental, or legal inquiry, order, or proceeding;</li>
                                <li aria-level="2">Reasonably necessary as part of a valid subpoena, warrant, or other legally-valid request;</li>
                                <li aria-level="2">Reasonably necessary to enforce our Terms of Use, Privacy Policy, and other legal agreements;</li>
                                <li aria-level="2">Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or regulation), or security or technical issues; or</li>
                                <li aria-level="2">Reasonably necessary in our discretion to protect against imminent harm to the rights, property, or safety of Fit4Grit Academy, our users, employees, members of the public, or our Services.</li>
                                <li aria-level="2">We may also disclose data about you to our auditors and legal advisors in order to assess our disclosure obligations and rights under this Privacy Policy.</li>
                            </ul>
                        </li>
                        <li aria-level="1">During a Change in Control: If Fit4Grit Academy undergoes a business transaction like a merger, acquisition, corporate divestiture, or dissolution (including bankruptcy), or a sale of all or some of its assets, we may share, disclose, or transfer all of your data to the successor organization during such transition or in contemplation of a transition (including during due diligence).</li>
                        <li aria-level="1">After Aggregation/De-identification: we can disclose or use aggregate or de-identified data for any purpose.</li>
                        <li aria-level="1">With Your Permission: with your consent, we may share data to third parties outside the scope of this Privacy Policy.</li>
                    </ul>
                    <h2>5. Security</h2>
                    We use appropriate security based on the type and sensitivity of data being stored. As with any internet-enabled system, there is always a risk of unauthorized access, so it's important to protect your password and to contact us if you suspect any unauthorized access to your account.

                    Fit4Grit Academy takes appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal data that we collect and store. These measures vary based on the type and sensitivity of the data. Unfortunately, however, no system can be 100% secured, so we cannot guarantee that communications between you and Fit4Grit Academy, the Services, or any information provided to us in connection with the data we collect through the Services will be free from unauthorized access by third parties. Your password is an important part of our security system, and it is your responsibility to protect it. You should not share your password with any third party, and if you believe your password or account has been compromised, you should change it immediately and contact fit4grit.academy@gmail.com with any concerns.
                    <h2>6. Your Rights</h2>
                    You have certain rights around the use of your data, including the ability to opt out of promotional emails, cookies, and collection of your data by certain analytics providers. You can update or terminate your account from within our Services, and can also contact us for individual rights requests about your personal data. Parents who believe we've unintentionally collected personal data about their underage child should contact us for help deleting that information.
                    <h3>6.1 Your Choices About the Use of Your Data</h3>
                    You can choose not to provide certain data to us, but you may not be able to use certain features of the Services.
                    <ul>
                        <li aria-level="1">To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account. Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.</li>
                        <li aria-level="1">The browser or device you use may allow you to control cookies and other types of local data storage. Your wireless device may also allow you to control whether location or other data is collected and shared. You can manage Adobe's LSOs through their <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Website Storage Settings panel</a>.</li>
                        <li aria-level="1">To get information and control cookies used for tailored advertising from participating companies, see the consumer opt-out pages for the Network Advertising Initiative and Digital Advertising Alliance, or if you're located in the European Union, visit the Your Online Choices site. To opt out of Google's display advertising or customize Google Display Network ads, visit the <a href="https://www.google.com/settings/ads">Google Ads Settings page</a>. To opt out of Taboola's targeted ads, see the Opt-out Link in their <a href="https://www.taboola.com/cookie-policy">Cookie Policy</a>.</li>
                        <li aria-level="1">To opt out of allowing Google Analytics, Hotjar, Mixpanel, ZoomInfo, or Clearbit to use your data for analytics or enrichment, see the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>, <a href="https://www.hotjar.com/opt-out">Hotjar Opt-Out Cookie</a>, <a href="https://mixpanel.com/optout/">Mixpanel Opt-Out Cookie</a>, <a href="https://www.zoominfo.com/business/about-zoominfo/privacy-policy">ZoomInfo's policy</a>, and <a href="https://clearbit.com/privacy">Clearbit data claiming mechanism</a>.</li>
                    </ul>
                    If you have any questions about your data, our use of it, or your rights, contact us at fit4grit.academy@gmail.com.
                    <h3>6.2 Accessing, Updating, and Deleting Your Personal Data</h3>
                    You can access and update your personal data that Fit4Grit Academy collects and maintains as follows:
                    <ul>
                        <li aria-level="1">To update data you provide directly, log into your account and update your account at any time.</li>
                        <li aria-level="1">To terminate your account:, email <a href="mailto:fit4grit.academy@gmail.com">fit4grit.academy@gmail.com</a> to continue.
                            <ul>
                                <li aria-level="2">Please note: even after your account is terminated, some or all of your data may still be visible to others, including without limitation any data that has been (a) copied, stored, or disseminated by other users (including in course comment); (b) shared or disseminated by you or others (including in your shared content); or (c) posted to a third-party platform. Even after your account is terminated, we retain your data for as long as we have a legitimate purpose to do so (and in accordance with applicable law), including to assist with legal obligations, resolve disputes, and enforce our agreements. We may retain and disclose such data pursuant to this Privacy Policy after your account has been terminated.</li>
                            </ul>
                        </li>
                        <li aria-level="1">To request to access, correct, or delete your personal data, email fit4grit.academy@gmail.com. For your protection, we may require that the request be sent through the email address associated with your account, and we may need to verify your identity before implementing your request. Please note that we retain certain data where we have a lawful basis to do so, including for mandatory record-keeping and to complete transactions.</li>
                    </ul>
                    <h3>6.3 Our Policy Concerning Children</h3>
                    We recognize the privacy interests of children and encourage parents and guardians to take an active role in their children's online activities and interests. Children under 13 (or under 16 in the European Economic Area) should not use the Services without parental supervision. If we learn that we've collected personal data from a child under those ages, we will take reasonable steps to delete it.

                    Parents who believe that Fit4Grit Academy may have collected personal data from a child under those ages can submit a request that it be removed to fit4grit.academy@gmail.com.
                    <h2>7. Jurisdiction-Specific Rules</h2>
                    If you live in California, you have certain rights related to accessing and deleting your data, as well as learning who we share your data with. If you live in Australia, you have the right to make a formal complaint with the appropriate government agency. Users outside of the United States should note that we transfer data to the US and other areas outside of the European Economic Area.
                    <h3>7.1 Users in California</h3>
                    Users who are California residents have certain rights under the California Consumer Privacy Act, (“CCPA”). If you are an eligible California user, included in these rights are:
                    <ul>
                        <li aria-level="1"><b>“Right to Know”</b> — You have the right to request to know more about the categories and specific pieces of personal information that we have collected about you and access a copy of your personal information.</li>
                        <li aria-level="1"><b>“Right to Deletion”</b> — You have the right to request deletion of personal information that we have collected about you.</li>
                        <li aria-level="1"><b>“Right to Non-Discrimination”</b> — If you choose to exercise any of your rights under CCPA, Fit4Grit Academy will treat you like all other users. In other words, there is no penalty for exercising your rights under CCPA.</li>
                    </ul>
                    To exercise any of these rights under CCPA, please email fit4grit.academy@gmail.com. As part of the CCPA you may designate an authorized agent to make these requests on your behalf. For your protection, we may require that the request be sent through the email address associated with your account, and we may need to verify you and/or your agent's identity before fulfilling your request.

                    Additionally, for more information about the personal information we collect and how we collect it, please see the sections above entitled “What Data We Get” and “How We Get Data About You.”

                    To learn about the business and commercial purposes for which your personal information is collected and the categories of service providers who have access to your personal information, please see the sections above entitled “What We Use Your Data For” and “Who We Share Your Data With.”

                    Most importantly, per the CCPA, <b>Fit4Grit Academy does not sell your personal information or the personal information of any of our users</b>.

                    As a California resident, you also have the right to request certain details about what personal information we share with third parties for those third parties' direct marketing purposes. To submit your request, send an email to fit4grit.academy@gmail.com with the phrase “California Shine the Light” and include your mailing address, state of residence, and email address.

                    Since the internet industry is still working on Do Not Track standards, solutions, and implementations, we do not currently recognize or respond to browser-initiated Do Not Track signals.
                    <h3>7.2 Users in Nevada</h3>
                    As is the case for all of its users, <b>Fit4Grit Academy does not sell its users' personal information or personal data</b>. Nonetheless, if you are a resident of Nevada, and would like to request that we do not sell your personal information, you can request to opt out of the sale of covered information by emailing fit4grit.academy@gmail.com.
                    <h3>7.3 Users in Australia</h3>
                    If you are an Australian resident and you have a complaint, you may refer it to the office of the Australian Information Commissioner (“OAIC”). You can contact OAIC by visiting <a href="http://www.oaic.gov.au/">www.oaic.gov.au</a>; forwarding an email to enquiries@oaic.gov.au; telephoning 1300 363 992; or writing to OAIC at GPO Box 5218, Sydney NSW 2001.
                    <h3>7.4 Users Outside of the U.S.</h3>
                    Fit4Grit Academy, Inc. is headquartered in California, and in order to provide the Services to you, we must transfer your data to the United States and process it there. By visiting or using our Services, you consent to storage of your data on servers located in the United States. If you are using the Services from outside the United States, you consent to the transfer, storage, and processing of your data in and to the United States or other countries. Specifically, personal data collected in the United Kingdom (“UK”), Switzerland, and the European Economic Area (“EEA”) is transferred and stored outside those areas. Additionally, if you are located in the UK, EEA, or Switzerland, you also have the right to lodge a complaint with your data supervisory authority.

                    That data is also processed outside of the UK, Switzerland, and the EEA by our Fit4Grit Academy group companies, or our service providers, including to process transactions, facilitate payments, and provide support services as described in Section 4. We have entered into data processing agreements with our service providers that restrict and regulate their processing of your data on our behalf. By submitting your data or using our Services, you consent to this transfer, storage, and processing by Fit4Grit Academy and its processors.
                    <h2>8. Updates &amp; Contact Info</h2>
                    When we make a material change to this policy, we'll notify users via email, in-product notice, or another mechanism required by law. Changes become effective the day they're posted. Please contact us via email with any questions, concerns, or disputes.
                    <h3>8.1 Modifications to This Privacy Policy</h3>
                    From time to time, we may update this Privacy Policy. If we make any material change to it, we will notify you via email, through a notification posted on the Services, or as required by applicable law. We will also include a summary of the key changes. Unless stated otherwise, modifications will become effective on the day they are posted.

                    As permitted by applicable law, if you continue to use the Services after the effective date of any change, then your access and/or use will be deemed an acceptance of (and agreement to follow and be bound by) the revised Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.
                    <h3>8.2 Interpretation</h3>
                    Any capitalized terms not defined in this policy are defined as specified in Fit4Grit Academy's Terms and Conditions. Any version of this Privacy Policy in a language other than English is provided for convenience. If there is any conflict with a non-English version, you agree that the English language version will control.
                    <h3>8.3 Questions</h3>
                    If you have any questions, concerns, or disputes regarding our Privacy Policy, please feel free to contact our privacy team at fit4grit.academy@gmail.com.
                    <h1>Cookie Policy</h1>
                    <i>This Cookie Policy was last updated on August 03, 2020</i>
                    <h3>What are cookies?</h3>
                    Cookies are small text files stored by your browser as you browse the internet. They can be used to collect, store, and share data about your activities across websites, including on Fit4Grit Academy. Cookies also allow us to remember things about your visits to Fit4Grit Academy, like your preferred language, and to make the site easier to use.

                    We use both session cookies, which expire after a short time or when you close your browser, and persistent cookies, which remain stored in your browser for a set period of time. We use session cookies to identify you during a single browsing session, like when you log into Fit4Grit Academy. We use persistent cookies where we need to identify you over a longer period, like when you request that we keep you signed in.
                    <h3>Why does Fit4Grit Academy use cookies and similar technologies?</h3>
                    We use cookies and similar technologies like web beacons, pixel tags, or local shared objects (“flash cookies”), to deliver, measure, and improve our services in various ways. We use these cookies both when you visit our site and services through a browser and through our mobile app. As we adopt additional technologies, we may also gather additional data through other methods.

                    We use cookies for the following purposes:
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Authentication and security</b></td>
                                <td>
                                    <ul>
                                        <li aria-level="1">To log you into Fit4Grit Academy</li>
                                        <li aria-level="1">To protect your security</li>
                                        <li aria-level="1">To help detect and fight spam, abuse, and other activities that violate Fit4Grit Academy's agreements</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    For example, cookies help authenticate your access to Fit4Grit Academy and prevent unauthorized parties from accessing your accounts.
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Preferences</b></td>
                                <td>
                                    <ul>
                                        <li aria-level="1">To remember data about your browser and your preferences</li>
                                        <li aria-level="1">To remember your settings and other choices you've made</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    For example, cookies help us remember your preferred language or the country you're in, so we can provide content in your preferred language without asking each time you visit.
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Analytics and research</b></td>
                                <td>
                                    <ul>
                                        <li aria-level="1">To help us improve and understand how people use Fit4Grit Academy</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    For example, cookies help us test different versions of Fit4Grit Academy to see which features or content users prefer, web beacons help us determine which email messages are opened, and cookies help us see how you interact with Fit4Grit Academy, like the links you click on.

                    We also work with a number of analytics partners, including Google Analytics and Mixpanel, who use cookies and similar technologies to help us analyze how users use the Services, including by noting the sites from which you arrive. Those service providers may either collect that data themselves or we may disclose it to them.

                    You can opt out of some of these services through tools like the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on </a>and <a href="https://www.hotjar.com/opt-out">Hotjar Opt-Out Cookie</a>.
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Personalized content</b></td>
                                <td>
                                    <ul>
                                        <li aria-level="1">To customize Fit4Grit Academy with more relevant content</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    For example, cookies help us show a personalized list of recommended courses on the homepage.
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Advertising</b></td>
                                <td>
                                    <ul>
                                        <li aria-level="1">To provide you with more relevant advertising</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    To learn more about targeting and advertising cookies and how you can opt out, visit <a href="http://www.allaboutcookies.org/manage-cookies/index.html">www.allaboutcookies.org/manage-cookies/index.html</a>, or if you're located in the European Union, visit the <a href="http://www.youronlinechoices.eu/">Your Online Choices site</a>.

                    Please note that where advertising technology is integrated into the Services, you may still receive advertising on other websites and applications, but it will not be tailored to your interests.

                    When using mobile applications you may also receive tailored in-app advertisements. Apple iOS, Android OS, and Microsoft Windows each provide its own instructions on how to control in-app tailored advertising. For other devices and operating systems, you should review your privacy settings or contact your platform operator.
                    <h3>What are my privacy options?</h3>
                    You have a number of options to control or limit how we and our partners use cookies:
                    <ul>
                        <li aria-level="1">Most browsers automatically accept cookies, but you can change your browser settings to decline cookies by consulting your browser's support articles. If you decide to decline cookies, please note that you may not be able to sign in, customize, or use some interactive features in the Services.</li>
                        <li aria-level="1">Flash cookies operate differently than browser cookies, so your browser's cookie-management tools may not remove them. To learn more about how to manage Flash cookies, see Adobe's <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html">article on managing flash cookies</a> and <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Website Storage Settings panel</a>.</li>
                        <li aria-level="1">To get information and control cookies used for tailored advertising from participating companies, see the consumer opt-out pages for the <a href="http://www.networkadvertising.org/choices">Network Advertising Initiative</a> and <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance</a>, or if you're located in the European Union, visit the <a href="http://www.youronlinechoices.eu/">Your Online Choices site</a>. To opt out of Google Analytics' display advertising or customize Google Display Network ads, visit the <a href="https://www.google.com/settings/ads">Google Ads Settings page</a>.</li>
                        <li aria-level="1">For general information about targeting cookies and how to disable them, visit <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.</li>
                    </ul>
                    <h3>Updates &amp; Contact Info</h3>
                    From time to time, we may update this Cookie Policy. If we do, we'll notify you by posting the policy on our site with a new effective date. If we make any material changes, we'll take reasonable steps to notify you in advance of the planned change.

                    If you have any questions about our use of cookies, please email us at fit4grit.academy@gmail.com.
                </Container>
            </section>
            <Footer />
        </>
    )
}
