// React Router DOM
import { Link } from 'react-router-dom';

// Images
import studentAndInstructor from '../../assets/images/Student and Instructor.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function SignUp() {
    return (
        <>
            <Header title="Sign Up" />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 d-flex flex-column justify-content-center align-items-center align-items-md-start">
                            <h3>Students</h3>
                            <h2>Learn</h2>
                            <p className="text-center text-md-start">If you are a student interested in honing your skills, staying fit, and building confidence…</p>
                            <Link className="btn btn-secondary" type="button" to="/sign-up/students">Sign Up Now</Link>
                        </div>
                        <div className="col-md-4 d-flex flex-column justify-content-center">
                            <img src={studentAndInstructor} alt="student and instructor" />
                        </div>
                        <div className="col-md-4 d-flex flex-column justify-content-center align-items-center align-items-md-start">
                            <h3>Instructors</h3>
                            <h2>Teach</h2>
                            <p className="text-center text-md-start">If you want to make a difference to youth and share your skills by becoming an instructor…</p>
                            <Link className="btn btn-secondary" type="button" to="/sign-up/instructors">Sign Up Now</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
