// React
import { useState, useEffect, useContext } from 'react';

// React Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

// Formik
import { Formik, Field, Form } from 'formik';

// Yup
import * as Yup from 'yup';

// Firebase
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";

// classnames
import classNames from 'classnames';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons'

// React Router DOM
import { Link, useNavigate } from 'react-router-dom';

// CSS
import styles from './login.module.css';

// FontAwesome
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

// Images
import logo from '../../assets/images/Fit4Grit Logo.png';

// Context
import { AuthContext, UserDataContext } from '../../index';

// Components
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';

export default function Register() {

    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);
    const userData = useContext(UserDataContext);

    useEffect(() => {
        if (currentUser && userData) {
                if (userData.registration.isRegistered) {
                    navigate("/dashboard", { replace: true });
                }
        }
    });

    return (
        <section className={classNames("d-flex justify-content align-items-center", styles.login)}>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        {
                            !currentUser ?
                                <StepOne />
                                :
                                <StepTwo />
                        }
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

function StepOne() {

    const auth = getAuth();

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const formikConfig = {
        initialValues: {
            email: '',
            password: '',
            error: '',
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('Please enter a valid email')
                .required('Please enter an email'),
            password: Yup
                .string()
                .min(12, 'Password must be at least 12 characters')
                .matches(/^\S*$/, { excludeEmptyString: true, message: 'Password cannot contain spaces' })
                .matches(/[a-z]/, { excludeEmptyString: true, message: 'Password must contain at least one lowercase character' })
                .matches(/[0-9]+/, { excludeEmptyString: true, message: 'Password must contain at least one number' })
                .matches(/[A-Z]/, { excludeEmptyString: true, message: 'Password must contain at least one uppercase letter' })
                .matches(/[!@#$%^&*)(+=._-]/, { excludeEmptyString: true, message: 'Password must contain at least one special character' })
                .required('Please enter a password'),
        }),
        onSubmit: (values, formikBag) => {
            createUserWithEmailAndPassword(auth, values.email, values.password)
                .then((userCredential) => {
                    // Signed in
                })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log(errorCode);
                    if (errorCode === 'auth/email-already-in-use') {
                        formikBag.setFieldError('email', 'Email already exists');
                    } else
                        if (errorCode === 'auth/invalid-email') {
                            formikBag.setFieldError('email', 'Invalid email');
                        } else
                            if (errorCode === 'auth/too-many-requests') {
                                formikBag.setFieldError('error', 'Too many requests. Please try again later');
                            } else
                                if (errorCode === 'auth/user-disabled') {
                                    formikBag.setFieldError('error', 'This account is disabled. Please contact us for more information');
                                } else {
                                    formikBag.setFieldError('error', 'Something went wrong... Try again later');
                                }
                });
            formikBag.setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    }


    const googleProvider = new GoogleAuthProvider();
    const handleGoogleLogin = (setFieldError) => {
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                // Signed in
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                setFieldError('error', "Something went wrong... Try again later");
            });
    }

    const facebookProvider = new FacebookAuthProvider();
    const handleFacebookLogin = (setFieldError) => {
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                // Signed in
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                setFieldError('error', "Something went wrong... Try again later");
            });
    }

    return (
        <Formik {...formikConfig}>
            {({ errors, touched, isValid, isSubmitting, setFieldError }) => (
                <Form className="form d-flex flex-column justify-content-center align-items-center">
                    <img className={styles.logo} src={logo} />

                    <Input fieldName="email" type="email" placeholder="Email" autoComplete="on" />

                    <div className="form-group">
                        <Field name="password" >
                            {({ field, form, meta }) => (
                                <>
                                    <div className={classNames("form-control", { "error": meta.error })}>
                                        <input
                                            className="form-control-inner"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            autoComplete="on"
                                            {...field}
                                            onBlur={(e) => {
                                                form.handleBlur(e);
                                                form.validateField('password');
                                            }}
                                        />
                                        {showPassword ? <FontAwesomeIcon icon={faEye} onClick={toggleShowPassword} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={toggleShowPassword} />}
                                    </div>
                                    <p className="form-control-message error">{meta.error}</p>

                                </>
                            )}
                        </Field>
                    </div>

                    <Button variant="secondary" type="submit" disabled={!(touched.email && touched.password && isValid)}>
                        {isSubmitting ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} /> : "Register"}
                    </Button>

                    <div className={classNames("d-flex justify-content-center align-items-center", styles.providerLogin)}>
                        <div className={styles.googleIcon} onClick={() => handleGoogleLogin(setFieldError)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="24" width="24"><path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z"></path><path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"></path><path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"></path><path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"></path></svg>
                        </div>
                        <FontAwesomeIcon icon={faFacebook} className={styles.facebookIcon} onClick={() => handleFacebookLogin(setFieldError)} />
                    </div>

                    <p className={classNames("form-control-message", { "error": errors.error !== '' })}>{errors.error}</p>
                    <p className={styles.textSize}>Already Registered? <Link className={styles.textSize} to="/login">Login</Link></p>
                    <p className={styles.textSize}>1 / 2</p>
                </Form>
            )}
        </Formik>
    )
}

function StepTwo() {
 
    const currentUser = useContext(AuthContext);
    const db = getFirestore();
    const navigate = useNavigate();

    const formikConfig = {
        initialValues: {
            name: '',
            age: '',
            phone: '',
            privacyPolicy: false,
            termsAndConditions: false,
            studentAgreement: false,
            studentLiability: false,
            error: '',

        },
        initialTouched: {
            phone: true,
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .required('Please enter your student\'s name'),
            age: Yup
                .number()
                .required('Please enter your student\'s age'),
            phone: Yup
                .string()
                .optional(),
            privacyPolicy: Yup
                .boolean()
                .oneOf([true], 'You must accept the Privacy Policy to register'),
            termsAndConditions: Yup
                .boolean()
                .oneOf([true], 'You must accept the Terms and Conditions to register'),
            studentAgreement: Yup
                .boolean()
                .oneOf([true], 'You must accept the Student Agreement to register'),
            studentLiability: Yup
                .boolean()
                .oneOf([true], 'You must accept the Student Liability Waiver to register'),
        }),
        onSubmit: async (values, formikBag) => {
            const { name, age, phone, privacyPolicy, termsAndConditions, studentAgreement, studentLiability } = values;
            await setDoc(doc(db, "users", currentUser.uid), {
                information: {
                    name: name,
                    age: age,
                    phone: phone,
                },
                registration: {
                    privacyPolicy: privacyPolicy,
                    termsAndConditions: termsAndConditions,
                    studentAgreement: studentAgreement,
                    studentLiability: studentLiability,
                    isRegistered: true,
                }
            }, { merge: true });
            navigate("/dashboard", { replace: true });
            formikBag.setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    }

    return (
        <Formik {...formikConfig}>
            {({ touched, errors, isValid, isSubmitting }) => (
                <Form className="form d-flex flex-column justify-content-center align-items-center">
                    <img className={styles.logo} src={logo} />

                    <Input fieldName="name" placeholder="Student's Full Name" />

                    <Input fieldName="age" type="number" placeholder="Student's Age" min={0} max={100} />

                    <Input fieldName="phone" type="tel" placeholder="Phone (Optional)" autoComplete="on" />

                    <Checkbox fieldName="privacyPolicy" label="I have read and agree to the Privacy Policy" />

                    <Checkbox fieldName="termsAndConditions" label="I have read and agree to the Terms and Conditions" />

                    <Checkbox fieldName="studentAgreement" label="I have read and agree to the Student Agreement" />

                    <Checkbox fieldName="studentLiability" label="I have read and agree to the Student Liability Waiver" />

                    <Button variant="secondary" type="submit" disabled={isSubmitting || !(touched.name && touched.age && touched.privacyPolicy && touched.termsAndConditions && touched.studentAgreement && touched.studentLiability && isValid)}>
                        {isSubmitting ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} /> : "Register"}
                    </Button>

                    <p className={classNames("form-control-message", { "error": errors.error !== '' })}>{errors.error}</p>
                    <p className={styles.textSize}>Already Registered? <Link className={styles.textSize} to="/login">Login</Link></p>
                    <p className={styles.textSize}>2 / 2</p>
                </Form>
            )}
        </Formik>
    )
}
