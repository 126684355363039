// React
import { useLayoutEffect } from 'react';

// React Router DOM
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(props) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {props.children}
    </>
  )
};
