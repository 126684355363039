// React
import { useState } from 'react';

// React Bootstrap
import { Container, Row, Col, Modal } from 'react-bootstrap';

// Classnames
import classNames from 'classnames';

// CSS
import styles from './gallery.module.css';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';

// Gallery
import image1 from '../../assets/images/gallery/image 1.jpeg';
import image2 from '../../assets/images/gallery/image 2.jpeg';
import image3 from '../../assets/images/gallery/image 3.jpeg';
import video1 from '../../assets/videos/gallery/video 1.mp4';
import video2 from '../../assets/videos/gallery/video 2.mp4';
import video3 from '../../assets/videos/gallery/video 3.mp4';
import video4 from '../../assets/videos/gallery/video 4.mp4';
import video5 from '../../assets/videos/gallery/video 5.mp4';
import video6 from '../../assets/videos/gallery/video 6.mp4';
import video7 from '../../assets/videos/gallery/video 7.mp4';
import video8 from '../../assets/videos/gallery/video 8.mp4';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Gallery() {
    return (
        <>
            <Header title="Gallery" />
            <section>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Image src={image1} alt="Lead By Example Logo" />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Image src={image2} alt="Lead By Example Logo" />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Image src={image3} alt="Lead By Example Logo" />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video1} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video2} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video3} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video4} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video5} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video6} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video7} />
                        </Col>
                        <Col className={classNames("col-auto d-flex justify-content-center", styles.galleryCol)}>
                            <Video src={video8} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

function Image({ src, alt, ...props }) {

    const [show, setShow] = useState(false);

    return (
        <>
            <img className={styles.galleryImage} src={src} alt={alt} onClick={() => setShow(true)} {...props} />
            <Modal className={styles.galleryModal} show={show} onHide={() => setShow(false)} size="xl" centered>
                <Modal.Body className={styles.galleryModalBody}>
                    <img className={styles.galleryModalImage} src={src} alt={alt} />
                </Modal.Body>
            </Modal>
        </>
    )
}

function Video({ src, alt, ...props }) {

    const [show, setShow] = useState(false);

    return (
        <>
            <div className={styles.galleryThumbnailContainer} onClick={() => setShow(true)}>
                <video className={styles.galleryVideo} src={src} {...props} muted disabled />
                <FontAwesomeIcon className={styles.galleryIcon} icon={faCirclePlay} />
            </div>
            <Modal className={styles.galleryModal} show={show} onHide={() => setShow(false)} size="xl" centered>
                <Modal.Body className={styles.galleryModalBody}>
                    <video className={styles.galleryModalVideo} controls src={src} />
                </Modal.Body>
            </Modal>
        </>
    )
}
