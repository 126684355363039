// React Router DOM
import { Link } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Styles
import styles from './sign-up.module.css';

// classnames
import classNames from 'classnames';

// Images
import instructor from '../../assets/images/Instructor.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function InstructorSignUp() {
  return (
    <>
      <Header title="Instructor Registration" />
      <section>
        <Container>
          <h3 className="text-center">Learn</h3>
          <h2 className="text-center">Join Fit4Grit Academy</h2>
          <Row className="d-flex justify-content-center">
            <Col className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.col)}>
              {/* <h4 className={classNames("text-center", styles.col_title)}>Step 1</h4> */}
              <p className={classNames("text-center", styles.col_p)}>Thank you for your interest in becoming an volunteer instructor at Fit4Grit Academy. Please complete <a className={styles.col_link} href="https://forms.gle/z2BNQzRbYECSoK4p8" target="_blank" rel="noreferrer">this form</a>.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col d-none d-lg-flex justify-content-center align-items-center">
              <img className={styles.image} src={instructor} alt="students" />
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center align-items-lg-start">
              <h3 className="text-center text-lg-start">Required Reading</h3>
              <h2 className="text-center text-lg-start">Instructor Guidelines</h2>
              <ul>
                <li>Instructors must wear their martial arts uniform, unless they are unable to, which in that case, they must wear appropriate fitness clothing</li>
                <li>Instructors must have a device that has video and microphone for students to be able to hear them, as well as for them to be able to see and hear the students (for the real-time instructor classes)</li>
                <li>Instructors much sign and email their instructor agreement and instructor liability to <a href="mailto:info@fit4grit.org">info@fit4grit.org</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
