// React Bootstrap
import { Container } from 'react-bootstrap';

// Components
import NavBar from './NavBar';

export default function Header({ title }) {
  return (
    <>
      <NavBar />
      <section className="header">
        <div className="container header-container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center header-title">{title}</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
