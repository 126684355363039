// React
import { useState, useEffect, useContext, useLayoutEffect } from 'react';

// React Router DOM
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col, Stack, Tabs, Tab, Accordion, Modal, Button } from 'react-bootstrap';

// Firebase
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";

// Parser
import parse from 'html-react-parser';

// classnames
import classNames from 'classnames';

// Styles
import styles from './classes.module.css';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCalendarDays, faClock, faBook, faLock, faListUl, faCircle } from '@fortawesome/free-solid-svg-icons';

// Images
import Taekwondo from '../../assets/images/Taekwondo.svg';

// Context
import { AuthContext, UserDataContext, ClassesContext } from '../../index';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Classes() {

    const currentUser = useContext(AuthContext);
    const userData = useContext(UserDataContext);

    const classes = useContext(ClassesContext);
    const { classId } = useParams();
    const data = classes[classId];

    const [isEnrolled, setIsEnrolled] = useState(false);
    useEffect(() => {
        if (currentUser) setIsEnrolled(data?.students.includes(currentUser.uid));
    })

    const db = getFirestore();
    const handleEnroll = async () => {
        const classRef = doc(db, "classes", classId);
        await updateDoc(classRef, {
            students: arrayUnion(currentUser.uid)
        });
        setIsEnrolled(true);
    }

    const [isRegistered, setIsRegistered] = useState(false);
    useEffect(() => {
        if (userData) setIsRegistered(userData.registration.isRegistered);
    })

    const [key, setKey] = useState('course');

    return (
        <>
            <Header />
            <section className={styles.section1} >
                <Container>
                    {
                        !data ? (
                            <div className="d-flex justify-content-center">
                                <FontAwesomeIcon icon={faSpinner} className={styles.spinner} />
                            </div>
                        ) : (
                            <>
                                <h2 className="text-center">{data?.title}</h2>
                                <Row>
                                    <Col>
                                        <div className={styles.courseCard}>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img className={styles.course_img} src={data?.image ? data?.image : Taekwondo} alt="Course Image" />
                                            </div>
                                            <Tabs
                                                defaultActiveKey="course"
                                                transition={false}
                                                className="mb-3"
                                                activeKey={key}
                                                onSelect={(key) => setKey(key)}
                                            >
                                                <Tab eventKey="course" title="Course">
                                                    {
                                                        isEnrolled ? (
                                                            <Accordion defaultActiveKey="0">
                                                                {
                                                                    data?.modules.map((module, index) => {
                                                                        return (
                                                                            <Accordion.Item eventKey={index} key={index}>
                                                                                <Accordion.Header>{module.title}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ul className={classNames("list-unstyled", styles.ul)}>
                                                                                        {
                                                                                            module.items.map((item, index) => {
                                                                                                return (
                                                                                                    <Item key={index} item={item} index={index} />
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion>
                                                        ) : (
                                                            <div className={classNames("d-flex flex-column justify-content-center align-items-center", styles.lockedDiv)}>
                                                                <FontAwesomeIcon icon={faLock} className={styles.lockedIcon} />
                                                                <h4 className={classNames("text-center", styles.lockedText)}>You are not enrolled in this course</h4>
                                                            </div>
                                                        )
                                                    }
                                                </Tab>
                                                <Tab eventKey="annoucements" title="Announcements">
                                                    {
                                                        isEnrolled ? (
                                                            <Accordion defaultActiveKey="0">
                                                                {
                                                                    data?.announcements?.map((announcement, index) => {
                                                                        return (
                                                                            <Anouncement announcement={announcement} index={index} />
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion>
                                                        ) : (
                                                            <div className={classNames("d-flex flex-column justify-content-center align-items-center", styles.lockedDiv)}>
                                                                <FontAwesomeIcon icon={faLock} className={styles.lockedIcon} />
                                                                <h4 className={classNames("text-center", styles.lockedText)}>You are not enrolled in this course</h4>
                                                            </div>
                                                        )
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Col>

                                    <Col className="col-auto order-first order-md-last">
                                        <Stack className={styles.dataStack}>
                                            <Col className={classNames("d-flex flex-column", styles.infoCard)}>
                                                <div className={classNames("d-flex flex-row align-items-center justify-content-center justify-content-md-start", styles.instructor)}>
                                                    <img className={styles.instructorProfilePicture} src={data?.instructor.picture} alt="Instructor's Profile" />
                                                    <h4>{data?.instructor.name}</h4>
                                                </div>
                                                <Row className="d-flex flex-row justify-content-center justify-content-md-start">
                                                    <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                        <FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
                                                        <p className={styles.section2_col_data}>
                                                            {data?.days.length > 1 ? data?.days.map((day, index) => {
                                                                if (index === data?.days.length - 1) {
                                                                    return day.substring(0, 3);
                                                                } else {
                                                                    return day.substring(0, 3) + ", ";
                                                                }
                                                            }) : data?.days[0]}
                                                        </p>
                                                    </Col>
                                                    <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                        <FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
                                                        <p className={styles.section2_col_data}>
                                                            {data?.start + " - " + data?.end}
                                                        </p>
                                                    </Col>
                                                    <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                        <FontAwesomeIcon icon={faListUl} className={styles.section2_col_icon} />
                                                        <p className={styles.section2_col_data}>
                                                            {
                                                                data?.category === 1 ? "Real Time Virtual Class" : data?.category === 2 ? "Self-Paced Virtual Lessons" : "None"
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <p className="text-center text-md-start">
                                                    {data?.description}
                                                </p>
                                                <div className={classNames("d-flex flex-direction-column align-items-end", styles.buttonDiv)}>
                                                    {
                                                        !data?.archived ? isRegistered ? isEnrolled ? (
                                                            <Button className="align-self-end" variant="secondary" disabled>
                                                                Enrolled
                                                            </Button>
                                                        ) : (
                                                            <Button className="align-self-end" variant="secondary" onClick={() => handleEnroll()}>
                                                                Enroll
                                                            </Button>
                                                        ) : (
                                                            <Button className="align-self-end" variant="secondary" disabled>
                                                                You Are Not Registered
                                                            </Button>
                                                        ) : (
                                                            <Button className="align-self-end" variant="secondary" disabled>
                                                                This Class Is Archived
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                            <Col className={styles.infoCard}>
                                                <div>
                                                    <h4 className="text-center text-md-start">Difficulty Level</h4>
                                                    <ul className="d-flex flex-column text-center text-md-start list-unstyled">
                                                        <li className={classNames("d-inline-block", styles.listItem)}>
                                                            <FontAwesomeIcon className={styles.dot} icon={faCircle} />{data?.difficulty}</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h4 className="text-center text-md-start">Audience</h4>
                                                    <ul className="d-flex flex-column text-center text-md-start list-unstyled">
                                                        {data?.audience.map((target, index) => (
                                                            <li className={classNames("d-inline-block", styles.listItem)} key={index}>
                                                                <FontAwesomeIcon className={styles.dot} icon={faCircle} />
                                                                {target}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h4 className="text-center text-md-start">Requirements</h4>
                                                    <ul className="d-flex flex-column text-center text-md-start list-unstyled">
                                                        {data?.requirements.map((requirement, index) => (
                                                            <li className={classNames("d-inline-block", styles.listItem)} key={index}>
                                                                <FontAwesomeIcon className={styles.dot} icon={faCircle} />
                                                                {requirement}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Stack>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </Container>
            </section >
            <Footer />
        </>

    )
}

function Item({ item, index }) {
    const [show, setShow] = useState(false);

    const handleHide = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <li className={styles.li} key={index}>
            <a className={styles.lia} href="#" onClick={handleShow}>
                <FontAwesomeIcon icon={faBook} className={styles.li_icon} />
                {item.title}
            </a>
            <Modal show={show} onHide={handleHide} centered={true} scrollable={true} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{item.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        parse(item.content ? item.content : "<p>There is no content for this item.</p>")
                    }
                </Modal.Body>
            </Modal>
        </li>
    );
}

function Anouncement({ announcement, index }) {
    const [show, setShow] = useState(false);

    const handleHide = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Accordion.Item eventKey={index} key={index}>
            {/* <Accordion.Header onClick={handleShow}>{announcement.title}</Accordion.Header> */}
            <h2 className="accordion-header">
                <Accordion.Button onClick={handleShow}>{announcement.title}</Accordion.Button>
            </h2>
            <Modal show={show} onHide={handleHide} centered={true} scrollable={true} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{announcement.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        parse(announcement.content ? announcement.content : "<p>There is no content for this page.</p>")
                    }
                </Modal.Body>
            </Modal>
        </Accordion.Item>
    )
}
