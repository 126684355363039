// Formik
import { Field } from 'formik';

// classnames
import classNames from 'classnames';

export default function Checkbox({ fieldName, label }) {
    return (
        <div className="form-group form-checkbox">
            <Field name={fieldName} >
                {({ field, form, meta }) => (
                    <>
                        <div className={classNames("form-control form-control-checkbox", "d-flex flex-row justify-content-center align-items-center", { "error": meta.error })}>
                            <input className="form-control-inner form-control-checkbox-inner" name={fieldName} type="checkbox" {...field}
                                onChange={async (e) => {
                                    await form.handleChange(e);
                                    form.validateField(fieldName);
                                }} />
                            <p className="form-control-checkbox-label">{label}</p>
                        </div>
                        <p className="text-center form-control-message error">{meta.error}</p>
                    </>
                )}
            </Field>
        </div>
    );
}