// React
import { useState, useRef, useEffect } from 'react';

// React Bootstrap
import { Container, Row, Col, Carousel, Modal } from 'react-bootstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCirclePlay } from '@fortawesome/free-solid-svg-icons';

// classnames
import classNames from 'classnames';

// Images
import boxingGloves from '../../assets/images/Culture Class.svg';
import adarsh from '../../assets/images/instructors/Adarsh.png';
import shalini from '../../assets/images/instructors/Shalini.png';
import tanish from '../../assets/images/instructors/Tanish.jpeg';
import anshul from '../../assets/images/instructors/Anshul.png';
import krystal from '../../assets/images/instructors/Krystal.jpg';
import aditi from '../../assets/images/instructors/Aditi.jpg';
import sandeep from '../../assets/images/instructors/Sandeep.jpg';
import ronit from '../../assets/images/instructors/Ronit.png';

import tiger from '../../assets/images/partners/tiger.png';
import dawnBreaker from '../../assets/images/partners/Dawn Breaker logo.png';
import paradiseValley from '../../assets/images/partners/Paradise Valley Schools.png';

// Partners
import covitrain from '../../assets/images/partners/covitrain.jpg';
import dancing_with_a_purpose from '../../assets/images/partners/dancing_with_a_purpose.jpg';
import fit_4_a_cure from '../../assets/images/partners/fit_4_a_cure.jpg';
import forever_coders from '../../assets/images/partners/forever_coders.jpg';
import health_help_and_happiness from '../../assets/images/partners/health_help_and_happiness.jpg';
import hearo from '../../assets/images/partners/hearo.jpg';
import lead_by_example from '../../assets/images/partners/lead_by_example.jpg';

// Videos
import bgVideo from '../../assets/videos/Fit4Grit Forms Video.mp4';

// CSS
import styles from './about_us.module.css';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function AboutUs() {

    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    const handleWindowSizeChange = () => {
        setHeight(ref.current ? ref.current.offsetHeight : 0);
    };

    // call your useEffect
    useEffect(() => {
        setHeight(ref.current ? ref.current.offsetHeight : 0);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => { window.removeEventListener('resize', handleWindowSizeChange); }
    }, []);

    const instructors = [
        {
            name: "Adarsh Gupta",
            image: adarsh,
            description: "Adarsh is the Founder, Chief Executive Officer, and head instructor of Fit4Grit Academy. He is a 2nd-degree verified black belt instructor in Taekwondo, is a level three in self-defense, and is certified in CPR, First Aid, Concussion Safety, and Harassment Prevention. He trains at Tiger Martial Arts. He loves teaching students and spreading martial arts. He also plays golf and dances, as well as does speech and debate."
        },
        {
            name: "Tanish Kher",
            image: tanish,
            description: "Tanish is the Head of Social Media Outreach and a verified instructor of Fit4Grit Academy. He is a 2nd Degree verified black belt instructor in Taekwondo. He trains at Tiger Martial Arts Academy. He has been practicing Taekwondo for many years and also plays basketball and participates in speech and debate."
        },
        {
            name: "Anshul Noori",
            image: anshul,
            description: "Anshul is the Chief Technology Officer and Head of Web Development at Fit4Grit Academy."
        },
        {
            name: "Shalini Singh",
            image: shalini,
            description: "Shalini is a Head of Internal Relations and verified instructor of Fit4Grit Academy. She is a 1st degree verified black belt instructor in Wushu and is an internationally top-ranked Wushu competitor. She trains at Elite Kung Fu. She enjoys practicing wushu and working-out, as well as participating in speech and debate."
        },
        {
            name: "Krystal Lan",
            image: krystal,
            description: "Krystal is a verified instructor at Fit4Grit Academy. She is also a black belt in Shaolin Kungfu and has been competing both locally and internationally for many years. She plays basketball, volunteers, and also loves to tinker in her free time. She has worked closely with other instructors and taught martial arts before to varying age groups."
        },
        {
            name: "Aditi Indra",
            image: aditi,
            description: "Aditi is a verified instructor at Fit4Grit Academy."
        },
        {
            name: "Sandeep Bajamahal",
            image: sandeep,
            description: "Sandeep is a verified instructor at Fit4Grit Academy."
        },
        {
            name: "Ronit Jain",
            image: ronit,
            description: "Ronit is a 1st-degree black belt and verified instructor at Fit4Grit Academy. He has been training for many years and also plays soccer in the National Premier League as a part of the Palo Alto Soccer Club. Ronit loves working with and teaching others. Some of his hobbies include speech and debate, basketball, and football."
        }
    ]

    return (
        <>
            <Header title="About Us" />
            <section id="history">
                <div className="container">
                    <div className="row">
                        <div className="col d-none d-md-flex"><img src={boxingGloves} className={styles.section1_image} /></div>
                        <div className="col d-flex flex-column justify-content-center align-items-center align-items-md-start">
                            <h3>History</h3>
                            <h2>How we started</h2>
                            <p className="text-center text-md-start">Fit4Grit Academy, was launched in 2020 soon after the onset of the pandemic. The Academy was designed to serve youth with an underlying goal to build spirit, confidence, discipline, respect and tenacity.  Our roots stem from the teaching experience of the founder Adarsh, who taught martial art classes to youth while continuing his own training as a black belt student in Taekwondo. At the onset of the pandemic, Adarsh moved his teaching efforts to casual on-line martial arts classes which quickly grew in popularity. Building from the experience of these initial no cost online classes, the current model of the Academy was formed. The non-profit has continued its virtual route, advancing its methodology and perfecting its virtual take on martial arts. The program has grown to include instructors from a wide range of martial arts and fitness backgrounds with subsequent classes offering a diverse skill set. At its inception, Fit4Grit offered its no cost services to youth in the U.S. and has since grown to expand its classes internationally with a focus on reaching at-risk youth in communities where opportunities are scarce.  We aim to continue to broaden our reach to empower youth across the globe.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="mission" className={classNames("d-flex", styles.section2)} ref={ref}>
                <video src={bgVideo} autoPlay loop className={styles.section2_bg_video} height={height} preload="auto" muted />
                <div className={styles.section2_bg_overlay} style={{ height: height }} />
                <div className="container d-flex justify-content-center align-items-center">
                    <div className="row d-flex">
                        <div className="col">
                            <h3 className="text-center">Mission</h3>
                            <h2 className={classNames("text-center", styles.text_color_white)}>Why We Exist</h2>
                            <Video src="https://www.youtube.com/watch?v=UjOFB-mWl4A" />
                            <p className={classNames("text-center", styles.text_color_white)}>Confidence, communication skills, emotional intelligence, human connection, self-advocacy, self-expression, discipline, mindfulness and a whole array of other non-strictly- academic skills are better predictors of both personal and professional success compared to narrowly measured literary evaluations. Jointly with mental well being is the importance of physical fitness, especially amongst the next generation of youth that face a growing pandemic of chronic diseases secondary to poor fitness in an ever convenient digital age.</p>
                            <p className={classNames("text-center", styles.text_color_white)}>At Fit4Grit, we work to leverage the benefits of an electronically connected world, to bring the multitude of advantages that martial arts training offers to youth. Our mission is to empower youth, build confidence, teach self-defense, and encourage physical fitness, through no cost martial arts classes delivered virtually throughout the U.S. and across the globe.</p>
                            <p className={classNames("text-center", styles.text_color_white)}>We have consciously constructed a program that deemphasizes belt systems. Instead we focus on the inner spirit and a deeper understanding of the martial arts. We are privileged to be able to also provide students an opportunity to explore various martial arts with our program to allow further pursuit and study within a discipline of their choosing.</p>
                            <p className={classNames("text-center", styles.text_color_white)}>Ultimately, our mission at Fit4Grit is to mentor youth through martial arts and arm them with skills critical for their personal and professional success in our globalized and evolving world.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <p className="text-center text-md-start">On the issue of confidence, martial arts teaches our students to work towards improving themselves and the world around them. By teaching important topics like respect, discipline, and self-control, instructors will guide students on the path to becoming better global citizens and martial artists. With continuous classes and check-ins, students are likely – and have been seen to be in our trial classes – to have much more confidence in themselves and their actions than they had before.</p>
                            <p className="text-center text-md-start">Secondly, we will address fitness and health. Though martial arts is an art form, working out, training and sweating immensely is a norm that we are proud to have. We will teach our students the right way to exercise, train, and practice to remain safe, healthy, and prosperous during their childhood or young adult life. And while major health issues, such as obesity and diabetes do persist in our world, we hope to take direct positive impacts to prevent and reduce these diseases to make our students as healthy as possible.</p>
                            <p className="text-center text-md-start">Finally, we would like to address the very important and serious topic of assault and abuse. Unfortunately, these problems are exorbitant, and our goal is to make a difference no matter how small it may be. We work to teach our students how to apply the techniques they learn in className to help them get out of dangerous situations. But of course, the first thing we teach our students is to prevent dangerous situations and how to resolve them nonviolently. Also, we work to make sure that our students do not practice the techniques taught in className by their friends and family. Overall, we hope to teach our students some basics that may help them in the future and prepare them for attending in-depth classes with martial art paid schools and academies.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="team">
                <Container>
                    <h3 className="text-center">Our Instructors</h3>
                    <h2 className="text-center">Who We Are</h2>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Carousel className={classNames("d-flex justify-content-center align-items-center", styles.section4_slider)} interval={null} prevIcon={<FontAwesomeIcon icon={faAngleLeft} className={styles.section4_slider_prev} />} nextIcon={<FontAwesomeIcon icon={faAngleRight} className={styles.section4_slider_next} />}>
                                {
                                    instructors.map((instructor, index) => {
                                        return (
                                            <Carousel.Item className={styles.section4_slide} key={index}>
                                                <Row className="d-flex justify-content-center">
                                                    <Col className={classNames("d-flex justify-content-center align-items-center", styles.section4_slide_col, styles.section4_slide_image_col)}>
                                                        <img src={instructor.image} className={styles.section4_slide_image} alt={"photo of " + instructor.name} />
                                                    </Col>
                                                    <Col className={classNames("d-flex flex-column justify-content-center align-items-center", styles.section4_slide_col)}>
                                                        <h3 className={classNames("text-center", styles.section4_slide_title)}>{instructor.name}</h3>
                                                        <p className={classNames("text-center", styles.section4_slide_description)}>{instructor.description}</p>
                                                    </Col>
                                                </Row>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="partners">
                <Container>
                    <Row>
                        <Carousel className={classNames("d-flex justify-content-center align-items-center partners", styles.carousel)} interval={5000} prevIcon={<FontAwesomeIcon icon={faAngleLeft} className={styles.section4_slider_prev} />} nextIcon={<FontAwesomeIcon icon={faAngleRight} className={styles.section4_slider_next} />}>
                            <Carousel.Item>
                                <div className="col d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="text-center">Martial Arts Partner</h3>
                                    <h2 className="text-center">Tiger Martial Arts Academy</h2><img className={styles.partnerLogo} src={tiger} alt="Tiger Martial Arts Academy Logo" />
                                    <p className="text-center">Tiger Martial Arts Academy in Sunnyvale, California, USA is a martial arts school that is founded around Taekwondo but also holds className with self-defense and weapons. They have classes for students of all age groups and have taught internationally ranked competitive athletes with highly experienced coaches. They are an excellent school to continue learning virtually and in-person when the time arrives.</p>
                                    <a className="btn btn-primary" role="button" href="https://tma-academy.net/" target="_blank">Visit Their Website</a>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="col d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="text-center">International Partner</h3>
                                    <h2 className="text-center">Dawn Breaker School</h2><img className={styles.partnerLogo} src={dawnBreaker} alt="Dawn Breaker Logo" />
                                    <p className="text-center">Dawn Breaker School in Nasik, India is a social-service school that teaches students a well-rounded education. They hold classes from 1st grade/standard to 11th grade/standard. They have used Instructor Adarsh's Fit4Grit virtual Zoom classes to teach their students martial arts and fitness throughout the pandemic and following the lockdown.</p>
                                    <a className="btn btn-primary" role="button" href="https://www.facebook.com/DawnBreakersSchool" target="_blank">Visit Their Website</a>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="col d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="text-center">School Partner</h3>
                                    <h2 className="text-center">Paradise Valley Unified School District</h2><img className={styles.partnerLogo2} src={paradiseValley} alt="Dawn Breaker Logo" />
                                    <p className="text-center">Paradise Valley Schools in Arizona teach youth to become strong leaders, as well as giving them a strong education foundation. A PV School partnered with Fit4Grit Academy at the start of the pandemic school year to bring pre-recorded martial art classes to their PE classes, allowing fitness to be prioritized and for self-defense to be taught.</p>
                                    <a className="btn btn-primary" role="button" href="https://www.pvschools.net/" target="_blank">Visit Their Website</a>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <h3 className="text-center">Our Partners</h3>
                    <h2 className="text-center">Together Making A Difference</h2>
                    <Row className="d-flex justify-content-center">
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={lead_by_example} alt="Lead By Example Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={hearo} alt="Hearo Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={health_help_and_happiness} alt="Health Help And Happiness Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={forever_coders} alt="Forever Coders Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={fit_4_a_cure} alt="Fit 4 A Cure Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={dancing_with_a_purpose} alt="Dancing With A Purpose Logo" />
                        </Col>
                        <Col className="col-auto d-flex justify-content-center">
                            <img className={styles.partnerLogo2} src={covitrain} alt="Covitrain Logo" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

function Video({ src, alt, ...props }) {

    const [show, setShow] = useState(false);

    return (
        <>
            <div className={styles.galleryThumbnailContainer} onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faCirclePlay} />
            </div>
            <Modal className={styles.galleryModal} show={show} onHide={() => setShow(false)} size="xl" centered>
                <Modal.Body className={styles.galleryModalBody}>
                    <video className={styles.galleryModalVideo} controls src={src} />
                </Modal.Body>
            </Modal>
        </>
    )
}
