// React
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

// React Router DOM
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, onSnapshot, doc, collection } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// CSS
import './styles.css';

// Components
import ProtectedRoutes from './pages/Auth/ProtectedRoutes';
import ScrollToTop from './components/ScrollToTop';

// Pages
import Home from './pages/Home';
import AboutUs from './pages/About Us';
import SignUp from './pages/Sign Up';
import StudentSignUp from './pages/Sign Up/Student Sign Up';
import InstructorSignUp from './pages/Sign Up/Instructor Sign Up';
import AuthSwap from './pages/Auth/Auth';
import Dashboard from './pages/Dashboard/';
import Classes, { Page } from './pages/Classes';
import FourZeroFour from './pages/404/';
import MartialArtsStyles from './pages/Martial Arts Styles/';
import Gallery from './pages/Gallery/';
import PrivacyPolicy from './pages/Privacy Policy/';
import TermsAndConditions from './pages/Terms And Conditions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LcWt1kiAAAAAMnmtiZswGM3pMXHW7jjwez06dI3'),

	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	isTokenAutoRefreshEnabled: true
});

export const AuthContext = React.createContext(null);
export const UserDataContext = React.createContext(null);
export const ClassesContext = React.createContext(null);

function App() {

	const auth = getAuth();
	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
		});
		return unsubscribe;
	}, []);

	const [userData, setUserData] = useState(null);

	useEffect(() => {
		if (currentUser) {
			const unsubscribe = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
				setUserData(doc.data());
			});
			return unsubscribe;
		}
	}, [currentUser]);

	const db = getFirestore();
	const [classes, setClasses] = useState({});

	useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, "classes"), (snapshot) => {
			snapshot.docChanges().forEach((change) => {
				setClasses((prev) => ({ ...prev, [change.doc.id]: change.doc.data() }));
			});
		});
		return unsubscribe;
	}, []);

	return (
		<AuthContext.Provider value={currentUser}>
			<UserDataContext.Provider value={userData}>
				<ClassesContext.Provider value={classes}>
					<Router>
						<ScrollToTop>
							<Routes>
								<Route path="/" exact element={<Home />} />
								<Route path="/about-us" exact element={<AboutUs />} />
								<Route path="/sign-up" exact element={<SignUp />} />
								<Route path="/sign-up/students" exact element={<StudentSignUp />} />
								<Route path="/sign-up/instructors" exact element={<InstructorSignUp />} />
								<Route path="/martial-arts-styles" element={<MartialArtsStyles />} />
								<Route path="/gallery" element={<Gallery />} />
								<Route path="/login" exact element={<AuthSwap />} />
								<Route path="/logout" exact element={<Logout />} />
								<Route element={<ProtectedRoutes />}>
									<Route path="/dashboard" exact element={<Dashboard />} />
								</Route>
								<Route path="/classes" element={<Page />} />
								<Route path="/classes/:classId" element={<Classes />} />
								<Route path="/privacy-policy" element={<PrivacyPolicy />} />
								<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
								<Route path="*" element={<FourZeroFour />} />
							</Routes>
						</ScrollToTop>
					</Router>
				</ClassesContext.Provider>
			</UserDataContext.Provider>
		</AuthContext.Provider>
	)
}

function Logout() {
	const navigate = useNavigate();
	const auth = getAuth();

	useEffect(() => {
		signOut(auth).then(() => {
			// Sign-out successful.
			navigate('/', { replace: true });
		}).catch((error) => {
			console.log(error);
		});
	}, [])
	return;
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const analytics = getAnalytics(app);
reportWebVitals(analytics);
