// REact
import { useEffect, useState, useContext } from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

// classnames
import classNames from 'classnames';

// CSS
import styles from './page.module.css';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';

// Images
import flexiblityOfMovement from '../../assets/images/Flexibility of Movement.svg';
import Taekwondo from '../../assets/images/Taekwondo.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// Context
import { ClassesContext } from '../../index';

export default function Page() {

    const classes = useContext(ClassesContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (classes) {
            setLoading(false);
        }
    }, [classes]);

    return (
        <>
            <Header title="Our Classes" />
            <section>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col>
                            <h3 className="text-center">Free and Global</h3>
                            <h2 className="text-center">Free Videos, Classes, And Seminars For Students</h2>
                            <p className="text-center">From our self-paced video lessons to our virtual real time classes, our mission is to spread self-defense, fitness, and confidence, starting at a root idea: guiding students on the correct path to begin their martial arts journey. Our multifaceted organization accommodates all children and teenagers. The few criteria we do ask our prospective students to meet are that they be in any grade from elementary school to college and are able to understand and follow basic English directions. Also, as interested students progress in martial arts, we hope for them to connect with advanced martial arts schools to continue their martial art and fitness education with more in-depth knowledge.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="real-time">
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col>
                            <h3 className="text-center text-md-start">Zoom Lessons</h3>
                            <h2 className="text-center text-md-start">Real Time Instructor-Led Virtual Classes</h2>
                            <p className="text-center text-md-start">From the time of the global pandemic of COVID-19, Fit4Grit Academy has diverged from the normal approach to martial arts by creating a platform to teach martial arts and fitness virtually globally to youth for free! With our virtual classes, students choose between martial art and fitness classes while staying in contact with instructors out of class. The classes will also have a foundation of fitness, self-defense, and confidence-building, all essential for a well-rounded martial artist and community member. For self-defense, students will be taught how to assess, respond, and avoid dangerous situations. In terms of confidence-building, Fit4Grit Academy adds elements of respect, discipline, and self-control to teach the student how to be well rounded martial artists and society members.</p>
                        </Col>
                        <Col className="d-flex justify-content-center order-first order-md-last">
                            <img src={flexiblityOfMovement} className={styles.image} alt="Flexiblity Of Movement" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className="d-flex justify-content-center">
                        {loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => classes[id].category === 1 && !classes[id].archived).length > 0 ? Object.keys(classes).filter((id) => classes[id].category === 1 && !classes[id].archived).map((id) => {
                            const data = classes[id];
                                return (
                                    <div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
                                        <img className={styles.section2_col_img} src={data.image ? data.image : Taekwondo} alt="Course Image" />
                                        <Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
                                            <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                <FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
                                                <p className={styles.section2_col_data}>
                                                    {data.days.length > 1 ? data.days.map((day, index) => {
                                                        if (index === data.days.length - 1) {
                                                            return day.substring(0, 3);
                                                        } else {
                                                            return day.substring(0, 3) + ", ";
                                                        }
                                                    }) : data.days[0]}
                                                </p>
                                            </Col>
                                            <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                <FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
                                                <p className={styles.section2_col_data}>
                                                    {data.start + " - " + data.end}
                                                </p>
                                            </Col>
                                        </Row>
                                        <h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
                                        <p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
                                        <Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
                                    </div>
                                )

                        }) : <p>There are no active Real Time Virtual Classes at the momment.</p>}
                    </Row>
                </Container>
            </section>
            <section id="self-paced">
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col className="d-flex justify-content-center">
                            <Row>
                                <Col className={classNames("d-flex flex-column justify-content-center align-items-center", styles.lessons_col)}>
                                    <h4 className={classNames("text-center", styles.col_title)}>Martial Arts Video Lesson Example</h4>
                                    <p className={classNames("text-center", styles.col_p)}>To see an excellent example of a martial art video lesson class, check out this video of Instructor Adarsh's Taekwondo Video Class!</p>
                                    <iframe width="435" height="245" src="https://www.youtube.com/embed/V2b10rnAuPg" title="Fit4Grit Self Paced Video Lesson 1 - Instructor Adarsh" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </Col>
                                <Col className={classNames("d-flex flex-column justify-content-center align-items-center", styles.lessons_col)}>
                                    <h4 className={classNames("text-center", styles.col_title)}>Fitness Video Lesson Example</h4>
                                    <p className={classNames("text-center", styles.col_p)}>To see an amazing example of a fitness video lesson class, check out this video of Instructor Sandeep's Fitness Video Class!</p>
                                    <iframe width="435" height="245" src="https://www.youtube.com/embed/1MXxDlj-5mk" title="Fit4Grit Virtual Workout 1(Total body workout)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="order-first order-md-last">
                            <h3 className="text-center text-md-start">Youtube Lessons</h3>
                            <h2 className="text-center text-md-start">Self-Paced Video Lessons</h2>
                            <p className="text-center text-md-start">Our self-paced free videos are a collection meant for students to watch on their own whenever they have time and internet connection. These videos will be separated by the instructor and martial art type. We recommend joining our free real-time virtual instructor-led classes, but if that is not an option for the student or if the class they are interested in is not being offered there, then the instructional videos are the next best thing. The videos will be made as guides specifically on martial art and confidence building, making the real-time classes so unique. Take this into account when choosing which paths work best for you. Joining the real-time classes does not stop you from watching the video lessons, and we actually encourage you to join both in order to learn more new martial arts! Below are more details on the different paths you can take for the self-paced video lessons.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className="d-flex justify-content-center">
                        {loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => classes[id].category === 2 && !classes[id].archived).length > 0 ? Object.keys(classes).filter((id) => classes[id].category === 2 && !classes[id].archived).map((id) => {
                            const data = classes[id];

                            if (!data.archived) {
                                return (
                                    <div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
                                        <img className={styles.section2_col_img} src={data.image ? data.image : Taekwondo} alt="Course Image" />
                                        <Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
                                            <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                <FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
                                                <p className={styles.section2_col_data}>
                                                    {data.days.length > 1 ? data.days.map((day, index) => {
                                                        if (index === data.days.length - 1) {
                                                            return day.substring(0, 3);
                                                        } else {
                                                            return day.substring(0, 3) + ", ";
                                                        }
                                                    }) : data.days[0]}
                                                </p>
                                            </Col>
                                            <Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
                                                <FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
                                                <p className={styles.section2_col_data}>
                                                    {data.start + " - " + data.end}
                                                </p>
                                            </Col>
                                        </Row>
                                        <h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
                                        <p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
                                        <Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
                                    </div>
                                )
                            }

                        }) : <p>There are no active Self-Paced Video Lessons at the momment.</p>}
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}
