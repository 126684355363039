// React
import { useState, useEffect, useContext } from 'react';

// React Bootstrap
import { Container, Row, Col, Tab, Nav, Tabs, Button } from 'react-bootstrap';

// React Router DOM
import { Link, useLocation } from 'react-router-dom';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faUser, faSpinner, faCalendarDays, faClock, faEnvelope, faRightFromBracket, faHandshake, faAddressCard, faCookieBite, faUserInjured, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';

// classnames
import classNames from 'classnames';

// Styles
import styles from './dashboard.module.css';

// Context
import { AuthContext, UserDataContext, ClassesContext } from '../../index';

// Images
import Taekwondo from '../../assets/images/Taekwondo.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Dashboard() {

	const location = useLocation();
	const currentUser = useContext(AuthContext);

	const [activeKey, setActiveKey] = useState("courses");

	return (
		<>
			<Header title="Dashboard" />
			<section className={styles.section1}>
				<Container>
					<div className={styles.welcomeContainer}>
						<img className={styles.profilePicture} src={currentUser ? currentUser.photoURL : Taekwondo} alt="Profile" referrerPolicy="no-referrer" />
						<div className={styles.innerTextContainer}>
							<h4 className={styles.welcomeText}>Welcome, </h4>
							<h2 className={styles.userName}>{currentUser ? currentUser.displayName : "Unknown"}</h2>
						</div>
					</div>
				</Container>
			</section>
			<section className={styles.section2}>
				<Container className={styles.container}>
					<Tab.Container id="left-tabs-example" defaultActiveKey="courses" onSelect={(k) => setActiveKey(k)} activeKey={location.state?.activeKey ? location.state?.activeKey : activeKey}>
						<Row>
							<Col sm={3} className={styles.leftCol}>
								<Nav variant="pills" className={classNames("flex-column", styles.tabsContainer)}>
									<Nav.Item>
										<Nav.Link className={styles.sectionLink} eventKey="courses">
											<FontAwesomeIcon icon={faGauge} className={styles.icon} />
											<span className={styles.navText}>My Courses</span>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className={styles.sectionLink} eventKey="profile">
											<FontAwesomeIcon icon={faUser} className={styles.icon} />
											<span className={styles.navText}>Profile</span>
										</Nav.Link>
									</Nav.Item>
								</Nav>
								<Nav variant="pills" className={classNames("flex-column", styles.logoutContainer)}>
									<Nav.Item>
										<Nav.Link className={styles.sectionLink} eventKey="logout" as={Link} to="/logout">
											<FontAwesomeIcon icon={faRightFromBracket} className={styles.icon} />
											<span className={styles.navText}>Logout</span>
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
							<Col sm={9} className={styles.rightCol}>
								<Tab.Content>
									<Tab.Pane eventKey="courses">
										<Courses />
									</Tab.Pane>
									<Tab.Pane eventKey="profile">
										<Profile />
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Container>
			</section>
			<Footer />
		</>
	)
}

function Courses() {

	const currentUser = useContext(AuthContext);
	const classes = useContext(ClassesContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (classes) {
			setLoading(false);
		}
	}, [classes]);

	return (
		<Tabs
			defaultActiveKey="active"
			transition={false}
			className="mb-3"
		>
			<Tab eventKey="active" title="Active Courses">
				<Row>
					{loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid) && !classes[id].archived).length > 0 ? Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid) && !classes[id].archived).map((id) => {
						const data = classes[id];

							return (
								<div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
									<img className={styles.section2_col_img} src={data.image ? data.image : Taekwondo} alt="Course Image" />
									<Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.days.length > 1 ? data.days.map((day, index) => {
													if (index === data.days.length - 1) {
														return day.substring(0, 3);
													} else {
														return day.substring(0, 3) + ", ";
													}
												}) : data.days[0]}
											</p>
										</Col>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.start + " - " + data.end}
											</p>
										</Col>
									</Row>
									<h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
									<p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
									<Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
								</div>
							)

					}) : <p className={styles.noClasses}>You are not registered in any active courses.</p>}
				</Row>
			</Tab>
			<Tab eventKey="archived" title="Archived Courses">
				<Row>
					{loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid) && classes[id].archived).length > 0 ? Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid) && classes[id].archived).map((id) => {
						const data = classes[id];

							return (
								<div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
									<img className={styles.section2_col_img} src={data.image ? data.image : Taekwondo} alt="Course Image" />
									<Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.days.length > 1 ? data.days.map((day, index) => {
													if (index === data.days.length - 1) {
														return day.substring(0, 3);
													} else {
														return day.substring(0, 3) + ", ";
													}
												}) : data.days[0]}
											</p>
										</Col>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.start + " - " + data.end}
											</p>
										</Col>
									</Row>
									<h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
									<p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
									<Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
								</div>
							)

					}) : <p className={styles.section2_col_p}>You are not registered in any archived courses.</p>}
				</Row>
			</Tab>
			<Tab eventKey="all" title="All Courses">
				<Row>
					{loading ? <FontAwesomeIcon icon={faSpinner} className={styles.spinner} /> : Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid)).length > 0 ? Object.keys(classes).filter((id) => classes[id].students.includes(currentUser?.uid)).map((id) => {
						const data = classes[id];
							return (
								<div className={classNames("col col-md-4 d-flex flex-column justify-content-center align-items-center", styles.section2_col)} key={id}>
									<img className={styles.section2_col_img} src={data.image ? data.image : Taekwondo} alt="Course Image" />
									<Row className={classNames("d-flex flex-row justify-content-center align-items-center", styles.row_col)}>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faCalendarDays} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.days.length > 1 ? data.days.map((day, index) => {
													if (index === data.days.length - 1) {
														return day.substring(0, 3);
													} else {
														return day.substring(0, 3) + ", ";
													}
												}) : data.days[0]}
											</p>
										</Col>
										<Col className={classNames("col-auto d-flex flex-row justify-content-center align-items-center", styles.section2_col_data_container)}>
											<FontAwesomeIcon icon={faClock} className={styles.section2_col_icon} />
											<p className={styles.section2_col_data}>
												{data.start + " - " + data.end}
											</p>
										</Col>
									</Row>
									<h4 className={classNames("text-center", styles.section2_col_title)}>{data.title}</h4>
									<p className={classNames("text-center", styles.section2_col_p)}>{data.description.substring(0, data.description.length < 150 ? data.description.length : 150)}...</p>
									<Button variant='secondary' as={Link} to={"/classes/" + id}>Open Class</Button>
								</div>
							)

					}) : <p className={styles.no_classes}>You are not registered in any courses.</p>}
				</Row>
			</Tab>
		</Tabs>
	)
}

function Profile() {

	const userData = useContext(UserDataContext);;

	return userData ? (
		<div>
			<ul className="list-unstyled">
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faUser} />
					<p>Student's Name: {userData?.information?.name}</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faClock} />
					<p>Student's Age: {userData?.information?.age}</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faEnvelope} />
					<p>Email: {userData?.information?.email}</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faAddressCard} />
					<p>You have {userData?.registration?.isRegistered ? "" : "not"} completed the registration process. {userData?.registration?.isRegistered ? "" : (<Link to="/login" replace={true} state={{ intent: "register" }}>Go to registration</Link>)}</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faCookieBite} />
					<p>You have {userData?.registration?.privacyPolicy ? "" : "not"} agreed to the Privacy Policy</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faBarsStaggered} />
					<p>You have {userData?.registration?.termsAndConditions ? "" : "not"} agreed to the Terms And Conditions</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faHandshake} />
					<p>You have {userData?.registration?.studentAgreement ? "" : "not"} agreed to the Student Agreement</p>
				</li>
				<li className={styles.section3_li}>
					<FontAwesomeIcon icon={faUserInjured} />
					<p>You have {userData?.registration?.studentLiability ? "" : "not"} agreed to the Student Liability Form</p>
				</li>
			</ul>
		</div>
	) : null;
}
