// React
import React, { useState, useEffect, useContext } from 'react';

// React Router DOM
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// React Boostrap
import { Container, Navbar, Nav, Dropdown, Button, Offcanvas } from 'react-bootstrap';

// classnames
import classNames from 'classnames';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Images
import logo from '../assets/images/Fit4Grit Logo.png';

// Context
import { AuthContext } from '../index';

export default function NavBar({ dark = false }) {

    const location = useLocation();
    const currentUser = useContext(AuthContext);

    return (
        <section>
            <Container className="nav-container">
                <Navbar expand="lg">
                    <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
                        <img src={logo} className="navbar-logo" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar-expand-lg"
                        aria-labelledby="offcanvasNavbaLabel-expand-lg"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbaLabel-expand-lg">
                                <img src={logo} className="navbar-logo" />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="mx-auto">
                                <DropdownMenu title="Home" route="/" dark={dark}>
                                    <DropdownHashItem title="About Us" route="/#about-us" />
                                    <DropdownHashItem title="Testimonials" route="/#testimonials" />
                                    <DropdownHashItem title="Popular Classes" route="/#popular" />
                                    <DropdownHashItem title="How You Can Help" route="/#help" />
                                    <DropdownHashItem title="Contact Us" route="/#contact-us" />
                                </DropdownMenu>
                                <DropdownMenu title="About Us" route="/about-us" dark={dark}>
                                    <DropdownHashItem title="How We Started" route="/about-us#history" />
                                    <DropdownHashItem title="Why We Exist" route="/about-us#mission" />
                                    <DropdownHashItem title="Who We Are" route="/about-us#team" />
                                    <DropdownHashItem title="Our Partners" route="/about-us#partners" />
                                </DropdownMenu>
                                <DropdownMenu title="Our Classes" route="/classes" dark={dark}>
                                    <DropdownHashItem title="Real Time Virtual Classes" route="/classes#real-time" />
                                    <DropdownHashItem title="Self-Paced Video Lessons" route="/classes#self-paced" />
                                </DropdownMenu>
                                <DropdownMenu title="Sign Up" route="/sign-up" dark={dark}>
                                    <DropdownItem title="Students" route="/sign-up/students" />
                                    <DropdownItem title="Instructors" route="/sign-up/instructors" />
                                </DropdownMenu>
                                <DropdownMenu title="Learn More" route="#" dark={dark}>
                                    <DropdownItem title="Martial Arts Styles" route="/martial-arts-styles" />
                                    <DropdownItem title="Gallery" route="/gallery" />
                                </DropdownMenu>
                                {
                                    currentUser ? (
                                        <>
                                            <Dropdown.Item className={classNames("nav-item", { "nav-item-dark": dark }, "d-flex d-lg-none account-dropdown-item")} active={location.state?.activeKey === "courses"} as={Link} to="/dashboard" state={{ activeKey: "courses" }} >My Courses</Dropdown.Item>
                                            <Dropdown.Item className={classNames("nav-item", { "nav-item-dark": dark }, "d-flex d-lg-none account-dropdown-item")} active={location.state?.activeKey === "profile"} as={Link} to="/dashboard" state={{ activeKey: "profile" }} >My Profile</Dropdown.Item>
                                            <Dropdown.Item className={classNames("nav-item", { "nav-item-dark": dark }, "d-flex d-lg-none account-dropdown-item")} as={Link} to="/logout">Logout</Dropdown.Item>
                                            <AccountDropdown className="d-none d-lg-flex" />
                                        </>
                                    ) : (
                                        <Button variant="secondary" as={Link} to="/login">Login</Button>
                                    )
                                }
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
            </Container>
        </section>
    )
}

function DropdownMenu({ title = 'Dropdown', route = '/', dark = false, children, ...props }) {
    const [isActive, setIsActive] = useState(false);
    const ref = React.createRef();

    const toggleIsActive = () => {
        setIsActive(!isActive);
    }

    useEffect(() => {
        if (!isActive) {
            ref.current.blur();
        }
    }, [ref.current, isActive]);

    return (
        <Dropdown onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)} {...props}>
            <Dropdown.Toggle ref={ref} as={Link} to={route} className={classNames("nav-item", { "nav-item-dark": dark })} aria-expanded={isActive} onClick={() => toggleIsActive()}>
                {title}
                <FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} className="nav-item-angle" />
            </Dropdown.Toggle>
            <div className="dropdown-spacer" />
            <Dropdown.Menu show={isActive} className={isActive ? "show" : ""}>
                {React.Children.map(children, child => {
                    return React.cloneElement(child);
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

function AccountDropdown({ className = '', ...props }) {

    const currentUser = useContext(AuthContext);
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    return (
        <Dropdown className={className} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
            <Dropdown.Toggle className="navbar-account-dropdown" as={Link} to="/dashboard" aria-expanded={isActive}>
                <img src={currentUser.photoURL} className="navbar-profile-pic" alt="profile picture" referrerPolicy="no-referrer" />
            </Dropdown.Toggle>
            <div className="dropdown-spacer" />
            <Dropdown.Menu show={isActive} className={isActive ? "show" : ""}>
                <Dropdown.Item active={location.state?.activeKey === "courses"} as={Link} to="/dashboard" state={{ activeKey: "courses" }} >My Courses</Dropdown.Item>
                <Dropdown.Item active={location.state?.activeKey === "profile"} as={Link} to="/dashboard" state={{ activeKey: "profile" }} >My Profile</Dropdown.Item>
                <Dropdown.Item as={Link} to="/logout">Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown >
    );
}

function DropdownItem({ title = 'Item', route = '/', ...props }) {

    const location = useLocation();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (location.pathname === route) setActive(true);
    }, [location, route]);

    return (
        <Dropdown.Item active={active} as={Link} to={route} {...props}>{title}</Dropdown.Item>
    )
}

function DropdownHashItem({ title = 'Item', route = '/#', ...props }) {

    const location = useLocation();
    const [active, setActive] = useState(false);

    useEffect(() => {
        const [pathname, hash] = route.split('#');
        setActive(location.pathname === pathname && location.hash === '#' + hash);
    }, [location, route]);

    return (
        <Dropdown.Item active={active} as={HashLink} to={route} {...props}>{title}</Dropdown.Item>
    )
}
