// React
import { useState, useEffect, useContext } from 'react';

// React Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

// Formik
import { Formik, Field, Form } from 'formik';

// Yup
import * as Yup from 'yup';

// Firebase
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// classnames
import classNames from 'classnames';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons'

// React Router DOM
import { Link, useNavigate } from 'react-router-dom';

// CSS
import styles from './login.module.css';

// FontAwesome
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

// Images
import logo from '../../assets/images/Fit4Grit Logo.png';

// Context
import { AuthContext } from '../../index';

// Components
import Input from '../../components/Input';

export default function Login({ redirectURL = "/dashboard" }) {
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const auth = getAuth();
    const currentUser = useContext(AuthContext);

    const formikConfig = {
        initialValues: {
            email: '',
            password: '',
            error: '',
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('Please enter a valid email')
                .required('Please enter an email'),
            password: Yup
                .string()
                .required('Please enter a password'),
        }),
        onSubmit: (values, formikBag) => {
            signInWithEmailAndPassword(auth, values.email, values.password)
                .then((userCredential) => {
                    // Signed in 
                    afterAuth();
                })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log(errorCode);
                    if (errorCode === 'auth/wrong-password') {
                        formikBag.setFieldError('password', 'Incorrect password');
                        return;
                    } else
                        if ((errorCode === 'auth/user-not-found') || (errorCode === 'auth/invalid-email')) {
                            formikBag.setFieldError('email', 'Email not found');
                        } else
                            if (errorCode === 'auth/too-many-requests') {
                                formikBag.setFieldError('error', 'Too many requests. Please try again later');
                            } else
                                if (errorCode === 'auth/user-disabled') {
                                    formikBag.setFieldError('error', 'This account is disabled. Please contact us for more information');
                                } else {
                                    formikBag.setFieldError('error', 'Something went wrong... Try again later');
                                }
                });
            formikBag.setSubmitting(false);
        },
        validateOnChange: false,
        validateOnBlur: false,
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const googleProvider = new GoogleAuthProvider();
    const handleGoogleLogin = (setFieldError) => {
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                // Signed in
                afterAuth();
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                setFieldError('error', "Something went wrong... Try again later");
            });
    }

    const facebookProvider = new FacebookAuthProvider();
    const handleFacebookLogin = (setFieldError) => {
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                // Signed in
                afterAuth();
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                setFieldError('error', "Something went wrong... Try again later");
            });
    }

    const resetPassword = (email, setFieldError) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                setFieldError('error', 'Password reset email sent');
            })
            .catch((error) => {
                const errorCode = error.code;
                if (errorCode === 'auth/user-not-found') {
                    setFieldError('email', 'Email not found');
                } else {
                    setFieldError('error', 'Something went wrong... Try again later');
                }
            });
    }

    const afterAuth = () => {
        navigate(redirectURL, { replace: true });
    }

    useEffect(() => {
        if (currentUser) afterAuth();
    });

    return (
        <section className={classNames("d-flex justify-content align-items-center", styles.login)}>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        <Formik {...formikConfig}>
                            {({ errors, touched, isValid, isSubmitting, setFieldError }) => (
                                <Form className="form d-flex flex-column justify-content-center align-items-center">
                                    <img className={styles.logo} src={logo} />

                                    <Input fieldName="email" type="email" placeholder="Email" autoComplete="on" />

                                    <div className="form-group">
                                        <Field name="password" >
                                            {({ field, form, meta }) => (
                                                <>
                                                    <div className={classNames("form-control", { "error": meta.error || errors.email })}>
                                                        <input
                                                            className="form-control-inner"
                                                            name="password"
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder="Password"
                                                            autoComplete="on"
                                                            {...field}
                                                            onBlur={(e) => {
                                                                form.handleBlur(e);
                                                                form.validateField('password');
                                                            }}
                                                        />
                                                        {showPassword ? <FontAwesomeIcon icon={faEye} onClick={toggleShowPassword} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={toggleShowPassword} />}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="form-control-message error">{meta.error}</p>
                                                        <p onClick={(e) => {
                                                            e.preventDefault();
                                                            form.validateField('email');
                                                            if (touched.email && !errors.email) resetPassword(form.values.email, setFieldError);
                                                        }} className={styles.resetPassword}>Forgot password?</p>
                                                    </div>

                                                </>
                                            )}
                                        </Field>
                                    </div>

                                    <Button variant="secondary" type="submit" disabled={!(touched.email && touched.password && isValid)}>
                                        {isSubmitting ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} /> : "Login"}
                                    </Button>

                                    <div className={classNames("d-flex justify-content-center align-items-center", styles.providerLogin)}>
                                        <div className={styles.googleIcon} onClick={() => handleGoogleLogin(setFieldError)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="24" width="24"><path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z"></path><path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"></path><path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"></path><path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"></path></svg>
                                        </div>
                                        <FontAwesomeIcon icon={faFacebook} className={styles.facebookIcon} onClick={() => handleFacebookLogin(setFieldError)} />
                                    </div>

                                    <p className={classNames("form-control-message", { "error": errors.error && errors.error !== 'Password reset email sent' })}>{errors.error}</p>
                                    <p className={styles.textSize}>Don't have an account? <Link className={styles.textSize} to="/login" state={{ intent: "register" }} replace={false}>Register Now</Link></p>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}


