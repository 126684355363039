// React Router DOM
import { Link } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Styles
import styles from './sign-up.module.css';

// classnames
import classNames from 'classnames';

// Images
import students from '../../assets/images/Students.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function StudentSignUp() {
  return (
    <>
      <Header title="Student Registration" />
      <section>
        <Container>
          <h3 className="text-center">Learn</h3>
          <h2 className="text-center">Join Fit4Grit Academy</h2>
          <Row className="d-flex justify-content-center">
            <Col className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.col)}>
              <h4 className={classNames("text-center", styles.col_title)}>Step 1</h4>
              <p className={classNames("text-center", styles.col_p)}>Create an account at <Link className={classNames(styles.col_link, styles.sign_up_link)} to={"/login"} state={{intent: "register"}}>fit4grit.org/login</Link></p>
            </Col>
            <Col className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.col)}>
              <h4 className={classNames("text-center", styles.col_title)}>Step 2</h4>
              <p className={classNames("text-center", styles.col_p)}>Agree to the Terms and Conditions, Privacy Policy, Student Agreement and Student Liability.</p>
            </Col>
            <Col className={classNames("col-md-4 d-flex flex-column justify-content-center align-items-center", styles.col)}>
              <h4 className={classNames("text-center", styles.col_title)}>Step 3</h4>
              <p className={classNames("text-center", styles.col_p)}>Start signing up for classes!</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col d-none d-lg-flex justify-content-center align-items-center">
              <img className={styles.image} src={students} alt="students" />
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center align-items-lg-start">
              <h3 className="text-center text-lg-start">Required Reading</h3>
              <h2 className="text-center text-lg-start">Student Guidelines</h2>
              <ul>
                <li>Students must have a device that allows them to see and hear the instructor, as well as for the instructor to see them (microphones are not necessary but are recommended).</li>
                <li>Students do not need any background in any martial arts when joining a class (as these are all basic beginner classes).</li>
                <li>Students are allowed to attend multiple classes of different martial art styles and types, but we recommend students to do a maximum of 2 different martial art styles and do multiple classes (with different instructors in the same martial art as well).</li>
                <li>Students are allowed to participate in classes as well as learn through the video lessons (see above for more details).</li>
                <li>Students do not need formal martial arts uniforms and should wear normal clothing that allows them to do the exercises and techniques as part of the class (if a student does have a formal uniform, please do wear it for classes).</li>
                <li>Students should learn and practice a large enough area to move around in for the classes (5ft x 5ft) and be in a safe environment (no sharp edges, slippery floors, open electrical wires, etc.).</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
