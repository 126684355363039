// React Router DOM
import { useLocation } from 'react-router-dom';

// Components
import Login from './Login';
import Register from './Register';

export default function AuthSwap() {

    const location = useLocation();

    return location.state?.intent === 'register' ? <Register /> : <Login redirectURL={location.state?.redirectURL} />
}
