// Images
import aikido from '../../assets/images/Aikido.svg';
import taekwondo from '../../assets/images/Taekwondo.svg';
import shaolinKungFu from '../../assets/images/Shaolin Kung Fu.svg';
import wushu from '../../assets/images/Wushu.svg';
import karate from '../../assets/images/Karate.svg';
import judo from '../../assets/images/Judo.svg';
import wrestling from '../../assets/images/Wrestling.svg';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function MartialArtsStyles() {
    return (
        <>
            <Header title="Martial Arts Styles" />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>KOREAN</h3>
                            <h2><strong>Hapkido</strong></h2>
                            <p>The Korean martial art of Hapkido, often referred to as the parent of most modern martial arts, is actually an “anti-martial art” and the first form of self-defense. In this way, Hapkido focuses on deflecting and blocking your opponent's attacks using joint locks, throws, grappling, and kicks. As well as a parent for martial arts, many private security, police, and other security personnel also practice this martial art form as it is able to subdue an opponent with very little force. This self-defense martial art teaches students to use as little force as necessary to protect themselves and is a great way to begin any self-defense in-depth teaching.</p>
                            <ul className="list-unstyled">
                                <li>Black Eagle Martial Arts, "What is Hapkido", <a href="https://blackeaglemartialarts.us/what-is-hapkido/" target="_blank">https://blackeaglemartialarts.us/what-is-hapkido/</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={aikido} /></div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={taekwondo} /></div>
                        <div className="col-md-6 d-flex flex-column justify-content-center">
                            <h3>KOREAN</h3>
                            <h2><strong>Taekwondo</strong></h2>
                            <p>Taekwondo is a traditional form of Korean martial arts. With a 5,000 year history, this form of martial arts has been used by many generations as a fitness and fighting method. Aside from physical fighting techniques, Taekwondo teaches the enhancement of our spirits and lives through the training of our bodies, minds, and spirits through the poomsaes, or forms, that embody the true meanings of Taekwondo and the world around us.</p>
                            <ul className="list-unstyled">
                                <li>Team USA, "What is Taekwondo", <a href="https://www.teamusa.org/usa-taekwondo/v2-getting-started-in-taekwondo/what-is-taekwondo/" target="_blank">https://www.teamusa.org/usa-taekwondo/v2-getting-started-in-taekwondo/what-is-taekwondo/</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>CHINESE</h3>
                            <h2><strong>Kung Fu</strong></h2>
                            <p>Kung Fu, a martial art originated in a Shaolin Temple of China, was first practiced in 600 A.D by monks who were pursuing good health and self-defense techniques. Kung Fu is a general term that encompasses a variety of forms of Chinese martial arts. Each Kung Fu style emphasizes both hard and soft elements, both moral and physical development, self-defense, and fitness. Kung Fu instills qualities of perseverance, discipline, and a calm disposition in its students.</p>
                            <ul className="list-unstyled">
                                <li>Pittsburgh Kung Fu, “What is Kung Fu?”, <a href="http://www.pittsburghkungfu.com/kungfu.htm/" target="_blank">http://www.pittsburghkungfu.com/kungfu.htm/</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={shaolinKungFu} /></div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={wushu} /></div>
                        <div className="col-md-6 d-flex flex-column justify-content-center">
                            <h3>CHINESE</h3>
                            <h2><strong>Wushu</strong></h2>
                            <p>Wushu, or the Chinese form of Kung Fu, embodies a variety of styles and expressions, each with different techniques, foundations, principles, methods, and weaponry. Over the past 5,000 years, Wushu has developed into an intricate martial art form that combines traditional fighting techniques with agility, explosive power, and martial intent. Along with self-defense, Wushu teaches its students the art of cultivating peace in the body, mind, and spirit, which are all applied to daily life situations.</p>
                            <ul className="list-unstyled">
                                <li>International Wushu Federation, “Sport Wushu”, <a href="http://www.iwuf.org/sport-wushu/" target="_blank">http://www.iwuf.org/sport-wushu/</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>Japanese</h3>
                            <h2><strong>Karate</strong></h2>
                            <p>Karate is a hard style of Japanese martial arts. This means that the movements are sharp and crisp as opposed to the circular “soft” movements of martial arts like Kung Fu. Karate is also a mindset, a way of life. Spirit, tactics, and timing are each equally as important as physical prowess. If you've ever watched a Karate competition or class, you'll see focused faces with determined looks. You'll also hear them yell sharply when striking, letting their inner power spill out and pour into the target.</p>
                            <p>The origin of the Karate system was first recognized on the island of Okinawa in Japan and it spawned from the indigenous Ryukyuan martial arts. You can check out the colorful history of karate at <a href="https://thekarateblog.com/karate-origin/" target="_blank">The Karate Blog</a>.</p>
                            <ul className="list-unstyled">
                                <li>Athletic Scholarships, “History of Karate”, <a href="https://www.athleticscholarships.net/history-of-karate.htm/" target="_blank">https://www.athleticscholarships.net/history-of-karate.htm/</a></li>
                                <li>Tulane Karate Club, “What Is Karate”, <a href="https://www.tulane.edu/~karate/karate.htm/" target="_blank">https://www.tulane.edu/~karate/karate.htm/</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={karate} /></div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={judo} /></div>
                        <div className="col-md-6 d-flex flex-column justify-content-center">
                            <h3>JAPANESE</h3>
                            <h2><strong>Judo</strong></h2>
                            <p>Judo is an intense combat sport that involves individuals using power and technique in an effort to pin each other on the ground. Even in its most basic form, Judo requires an incredible amount of physical and mental training, effort, time, and detail. Judo, however, is not a sport that involves striking at joints, punching or kicking, and weaponry; actually, “Judo” literally means “the way of gentleness”. Judo is a means of developing control and important values like discipline, perseverance, respect, and confidence.</p>
                            <ul className="list-unstyled">
                                <li>International Judo Federation, “What is Judo”, <a href="http://www.worldjudoday.com/en/WhatisJudo-57.html/" target="_blank">http://www.worldjudoday.com/en/WhatisJudo-57.html/</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>EUROPEAN</h3>
                            <h2><strong>Wrestling</strong></h2>
                            <p>Wrestling, also known as man's oldest sport, dates back to 15,000 years as indicated by carvings in the caves of Europe. This sport requires agility, speed, strength, power, tenacity, and resilience. Throughout history, wrestling has been a popular form of recreational combat that uses multiple holds and maneuvers as attack strategies.</p>
                            <ul className="list-unstyled">
                                <li>Bob Dellinger, “The Oldest Sport,” National Wrestling Hall of Fame, <a href="https://nwhof.org/stillwater/resources-library/history/the-oldest-sport/" target="_blank">https://nwhof.org/stillwater/resources-library/history/the-oldest-sport/</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center"><img src={wrestling} /></div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
