// React Bootstrap
import { Container, Row, Col } from "react-bootstrap";

// CSS
import styles from "./404.module.css";

// Images
import taekwondo from "../../assets/images/Taekwondo.svg";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function FourZeroFour() {
  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col className="d-flex flex-column align-items-center">
              <h3 className="text-center">Error</h3>
              <h2 className="text-center">404 Page Not Found</h2>
              <p className="text-center">The page you are looking for does not exist.</p>
              <img src={taekwondo} className={styles.image} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

